const intervals = new Map();
let intervalId = 0;

export function setIntervalHighPrecision(callback, interval) {
    let start = performance.now();
    let nextTime = start;
    let frameId;

    function frame(time) {
        if (time >= nextTime) {
            callback();

            // Planifie le prochain appel
            nextTime += interval;
        }

        // Continue à planifier le prochain frame
        frameId = requestAnimationFrame(frame);
    }

    frameId = requestAnimationFrame(frame);

    // Stocke l'identifiant de frame pour pouvoir l'arrêter plus tard
    intervalId++;
    intervals.set(intervalId, frameId);

    return intervalId;
}

export function clearIntervalHighPrecision(id) {
    if (intervals.has(id)) {
        cancelAnimationFrame(intervals.get(id));
        intervals.delete(id);
    }
}
