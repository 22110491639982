/**
 * # DateHelper
 * Petit objet nous permettant de :
 * - récupérer la date du jour
 * - convertir une date en chaine de caractère en un objet date (format attendu : dd/mm/yyyy ou dd-mm-yyyy)
 * - ajouter un nombre de jours à une date existante
 * - renvoyer si la date est pendant un weekend
 * - ajouter des jours ouvrés à une date
 */
const initDate = new Date();
export const DateHelper = {
  todayDate: function () {
    return initDate;
  },
  formatDateToObject: function (string) {
    let date_split;
    if (string === undefined) {
      return false;
    }

    if (string.indexOf("/") > -1) {
      date_split = string.split("/");
    } else if (string.indexOf("-") > -1) {
      date_split = string.split("-");
    } else {
      return false;
    }

    if (
      date_split[0] !== undefined &&
      date_split[1] !== undefined &&
      date_split[2] !== undefined
    ) {
      const day = date_split[0];
      const month = date_split[1] - 1;
      const year = date_split[2];

      return new Date(year, month, day);
    }
    return false;
  },
  addToDate: function (current_date, number_of_days) {
    const new_date = new Date(current_date);
    new_date.setDate(current_date.getDate() + number_of_days);
    return new_date;
  },
  isWeekend: function (current_date) {
    const _date = new Date(current_date);
    return _date.getDay() == 6 || _date.getDay() == 0;
  },
  addWDToDate: function (current_date, number_of_days) {
    let tempDate = current_date;
    let finalDate = current_date;
    let i = 1;
    while (i <= number_of_days) {
      tempDate = DateHelper.addToDate(tempDate, 1);
      if (!DateHelper.isWeekend(tempDate)) {
        finalDate = tempDate;
        i++;
      }
    }
    return finalDate;
  },
};
