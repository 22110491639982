//------------------------------------------------
export const checkStatusSubscribers = [];

/**
 *
 * @param fn
 */
export function subscribeToCheckStatus(fn) {
  checkStatusSubscribers.push(fn);
}

/**
 *
 * @param param
 */
export function checkStatus(param) {
  checkStatusSubscribers.forEach(function (fn) {
    fn(param);
  });
}
