import $ from "jquery";
import { DateHelper } from "./DateHelper.mjs";
import { docCookies } from "./docCookies.mjs";
import Chart from "Chart";
import { ShowHideHelper, FormHelper } from "../../plumbing/circularReferencesSolver.mjs";
/**
 * Fonction de formattage/conversion des chaines de caractères
 * Enleve tous les accents d'une chaine de caractères
 * Utilisé sur la recherche, pour avoir les mêmes résultats en tapant avec ou sans accent
 * {string}
 */
String.prototype.stripAccents = function () {
  const translate_re = /[àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ]/g;
  const translate = "aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY";
  return this.replace(translate_re, function (match) {
    return translate.substr(translate_re.source.indexOf(match) - 1, 1);
  });
};

/**
 * Trouve une chaine de caractère dans une caractère
 * {string} value   Chaine sur laquelle on se base
 * {string} keyword Chaine à rechercher
 * {bool}               True si trouvé, false sinon
 */
export function find(value, keyword) {
  return searchFormat(value).indexOf(keyword) >= 0;
}

/**
 * Formate la chaine de caractère pour la recherche, en minuscule, suppression des accents, et trim
 * {string} str Chaine de caractère à formater
 * {string}     La nouvelle chaine de caractère
 */
export function searchFormat(str) {
  if (str == undefined) {
    return "";
  }
  const new_str = String(str).toLowerCase().stripAccents();
  return $.trim(new_str);
}

/**
 * Tri sur la date
 * {string} field Le nom du champ à trier
 * {string}  L'élément classé
 */
export const sortByDate = (field) => (a, b) =>
  !(
    DateHelper.formatDateToObject(b[field]) &&
    DateHelper.formatDateToObject(a[field])
  )
    ? 1
    : DateHelper.formatDateToObject(b[field]).getTime() -
      DateHelper.formatDateToObject(a[field]).getTime();

/**
 * Tri sur les nombres (type montant)
 * {string} field Le nom du champ à trier
 * {string}  L'élément classé
 */
export function sortByNumber(field) {
  return function (a, b) {
    return b[field] - a[field];
  };
}

/**
 * Tri sur une chaine de caractère
 * {string} field Le nom du champ à classé
 * {int}  Un nombre pour classé les éléments
 */
export function sortByString(field) {
  return function (a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  };
}

/**
 * Obtient l'index maximum d'un tableau
 * {array} array
 * {int} index
 */
export function getIndexMaxOfArray(array) {
  return array.indexOf(Math.max.apply(null, array)); /**/
}

/**
 * Mise à jour du template handlebar avec les nouvelles données
 * par exemple trié, ou recherché
 * {object} $elem_placeholder jQuery object élément dans lesquels on réinsère les données
 * {function} template        Handlebar fonction permettant de compiler le template
 * {object} data              Les données à insérer dans le template
 * {void}
 */
export function updateTemplate($elem_placeholder, template, data) {
  $elem_placeholder.html(template(data));
}

/**
 * Boucle sur les valeurs de la ligne à faire correspondre avec les mots tapés
 * {array} keywords         Array splité sur les espaces
 * {array} values_to_match  Les valeurs de la ligne en cours contenu dans un tableau
 * {bool}
 */
export function checkValue(keywords, values_to_match) {
  let found = false,
    exit = false;

  $.each(keywords, function (idx, keyword) {
    // Si exit est set à false, on sort de la boucle car un des keyword n'a pas été trouvé
    if (exit) {
      found = false;
      return false;
    }

    $.each(values_to_match, function (index, value) {
      // Si le keyword match, on set found à true et on sort de la boucle pour regarder le keyword suivant
      if (find(value, keyword)) {
        found = true;
        return false;
      }
      // Si c'est le dernier élément on set exit à true (pas besoin de chercher sur les autre keywords)
      if (values_to_match.length == index + 1) {
        exit = true;
        found = false;
      }
    });
  });
  return found;
}

/**
 * Cherche sur une plage de montant
 * {int}    value       La valeur du montant du virement à comparer
 * {string} amount_from La valeur du montant (à partir de) que l'utilisateur a tapé
 * {string} amount_to   La valeur du montant (jusqu'à) que l'utilisateur a tapé
 * {bool}               True si trouvé, false sinon
 */
export function searchBetweenAmount(value, amount_from, amount_to) {
  return +amount_from <= +value && +amount_to >= +value;
}

/**
 * Cherche sur une plage de date
 * {object} value     Objet date à comparer
 * {object} date_from Objet date qui a été renseigné dans le champ (à partir de)
 * {object} date_to   Objet date qui a été renseigné dans le champ (jusqu'à)
 * {bool}             True si trouvé, false sinon
 */
export const searchBetweenDate = (value, date_from, date_to) =>
  date_from <= value && date_to >= value;

/**
 * delay
 * {function}
 */
export const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

/**
 * Array functions
 */
export const arrayFunctions = {
  sortMontant: function (a, b) {
    return Number(a) - Number(b);
  },
};

//Fonction stringReverse
//inverse une chaine de caractères
String.prototype.stringReverse = function () {
  return this.split("").reverse().join("");
};

//Fonction toTitle
//met une majuscule à la première lettre de chaque mot d'une chaine
String.prototype.toTitle = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

//Fonction uniform
//uniformise une chaine de caractère dans le style unix (pas d'espace, pas de majuscule, pas de tiret, des underscore à la place)
String.prototype.uniform = function () {
  return this.toLowerCase()
    .stripAccents()
    .replace(/(\s|-|')/gi, "_");
};

//Fonction stripAccents
//supprime les accents d'une chaine de caractères
String.prototype.stripAccents = function () {
  const accent = [
    /[\300-\306]/g,
    /[\340-\346]/g, // A, a
    /[\310-\313]/g,
    /[\350-\353]/g, // E, e
    /[\314-\317]/g,
    /[\354-\357]/g, // I, i
    /[\322-\330]/g,
    /[\362-\370]/g, // O, o
    /[\331-\334]/g,
    /[\371-\374]/g, // U, u
    /[\321]/g,
    /[\361]/g, // N, n
    /[\307]/g,
    /[\347]/g, // C, c
  ];
  const noaccent = [
    "A",
    "a",
    "E",
    "e",
    "I",
    "i",
    "O",
    "o",
    "U",
    "u",
    "N",
    "n",
    "C",
    "c",
  ];
  let str = this;
  for (let i = 0; i < accent.length; i++) {
    str = str.replace(accent[i], noaccent[i]);
  }
  return str;
};

// A function that returns the number of properties in an object.
Object.size = (obj) => Object.entries(obj).length;

/**
 * Fonctions ajoutées à jQuery
 */
$(function () {
  $.fn.extend({
    /**
     * slideOpen : fonction qui ouvre un élement progressivement vers le bas (comme slide Down)
     * void
     */
    slideOpen: function (delay, callback) {
      if (isNaN(delay) || typeof delay == "undefined") {
        delay = 300;
      }

      this.each(function () {
        if (!($(this).css("display") === "none")) {
          return;
        }
        $(this)
          .css({
            opacity: 0,
            display: "block",
          })
          .removeClass("hidden");
        const currentHeight = $(this).outerHeight();
        $(this)
          .css({
            height: 0,
            opacity: 1,
          })
          .animate(
            {
              height: currentHeight + "px",
            },
            delay,
            function () {
              $(this).css("height", "auto");
              if (typeof callback === "function") {
                callback.call(this);
              }
            }
          );
      });
      return this;
    },
  });
});

export function array_key_exists(key, search) {
  // discuss at: http://phpjs.org/functions/array_key_exists/
  // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Felix Geisendoerfer (http://www.debuggable.com/felix)
  // example 1: array_key_exists('kevin', {'kevin': 'van Zonneveld'});
  // returns 1: true

  if (
    !search ||
    (search.constructor !== Array && search.constructor !== Object)
  ) {
    return false;
  }

  return key in search;
}

export function getRGB(color) {
  // Function used to determine the RGB colour value that was passed as HEX
  let result;

  // Look for rgb(num,num,num)
  if (
    (result =
      /rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/.exec(
        color
      ))
  )
    return [parseInt(result[1]), parseInt(result[2]), parseInt(result[3])];

  // Look for rgb(num%,num%,num%)
  if (
    (result =
      /rgb\(\s*([0-9]+(?:\.[0-9]+)?)\%\s*,\s*([0-9]+(?:\.[0-9]+)?)\%\s*,\s*([0-9]+(?:\.[0-9]+)?)\%\s*\)/.exec(
        color
      ))
  )
    return [
      parseFloat(result[1]) * 2.55,
      parseFloat(result[2]) * 2.55,
      parseFloat(result[3]) * 2.55,
    ];

  // Look for #a0b1c2
  if (
    (result = /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/.exec(color))
  )
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];

  // Look for #fff
  if ((result = /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/.exec(color)))
    return [
      parseInt(result[1] + result[1], 16),
      parseInt(result[2] + result[2], 16),
      parseInt(result[3] + result[3], 16),
    ];
}

export function parseRGBA(color) {
  let result;

  if (
    (result =
      /rgba\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9,\.]{1,})\s*\)/.exec(
        color
      ))
  )
    return [
      parseInt(result[1]),
      parseInt(result[2]),
      parseInt(result[3]),
      parseFloat(result[4]),
    ];
}

export function makeRGBColor(arrayOfThreeValues) {
  return Array.isArray(arrayOfThreeValues) && arrayOfThreeValues.length == 3
    ? "rgb(" +
        arrayOfThreeValues[0] +
        ", " +
        arrayOfThreeValues[1] +
        ", " +
        arrayOfThreeValues[2] +
        ")"
    : "rgb(0, 0, 0)";
}

export function makeRGBAColor(arrayOfThreeValues, opacity) {
  return Array.isArray(arrayOfThreeValues) && arrayOfThreeValues.length == 3
    ? `rgba(${arrayOfThreeValues[0]}, ${arrayOfThreeValues[1]}, ${arrayOfThreeValues[2]}, ${opacity})`
    : "rgb(0, 0, 0)";
}

/* *****************************  */
/* TEST sur FONTS chargées ou non */
/* *****************************  */

export function waitForFontLoaded(fonts, callback) {
  if (!(fonts instanceof Array)) {
    fonts = [fonts];
  }

  const random = "giItT1WQy@!-/#", // random characters that usually differ in width depending on the font
    loader = [];

  for (let i = 0; i < fonts.length; i++) {
    const span = $("<span>")
      .text(random)
      .css({
        "font-family": "sans-serif",
        "font-variant": "normal",
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": "50px",
        "letter-spacing": "0",
        position: "absolute",
        left: "-2000px",
        top: "-2000px",
      })
      .appendTo("body");
    loader.push([span, span.outerWidth()]);
    span.css("font-family", fonts[i]);
  }

  const interval = setInterval(function () {
    let count = 0;
    for (let i = 0; i < loader.length; i++) {
      if (loader[i][0].outerWidth() != loader[i][1]) {
        count++;
      }
    }
    if (count == loader.length) {
      clearInterval(interval);
      for (let i = 0; i < loader.length; i++) {
        loader[i][0].remove();
      }
      callback();
    }
  }, 50);
}
/* *****************************  */
/* *****************************  */
/* *****************************  */

//Fonctions d'easing (récupérées de chart js)
export const easingEffects = {
  linear(t) {
    return t;
  },
  easeInQuad(t) {
    return t * t;
  },
  easeOutQuad(t) {
    return -1 * t * (t - 2);
  },
  easeInOutQuad(t) {
    if ((t /= 1 / 2) < 1) return (1 / 2) * t * t;
    return (-1 / 2) * (--t * (t - 2) - 1);
  },
  easeInCubic(t) {
    return t * t * t;
  },
  easeOutCubic(t) {
    return 1 * ((t = t / 1 - 1) * t * t + 1);
  },
  easeInOutCubic(t) {
    if ((t /= 1 / 2) < 1) return (1 / 2) * t * t * t;
    return (1 / 2) * ((t -= 2) * t * t + 2);
  },
  easeInQuart(t) {
    return t * t * t * t;
  },
  easeOutQuart(t) {
    return -1 * ((t = t / 1 - 1) * t * t * t - 1);
  },
  easeInOutQuart(t) {
    if ((t /= 1 / 2) < 1) return (1 / 2) * t * t * t * t;
    return (-1 / 2) * ((t -= 2) * t * t * t - 2);
  },
  easeInQuint(t) {
    return 1 * (t /= 1) * t * t * t * t;
  },
  easeOutQuint(t) {
    return 1 * ((t = t / 1 - 1) * t * t * t * t + 1);
  },
  easeInOutQuint(t) {
    return (t /= 1 / 2) < 1
      ? (1 / 2) * t * t * t * t * t
      : (1 / 2) * ((t -= 2) * t * t * t * t + 2);
  },
  easeInSine(t) {
    return -1 * Math.cos((t / 1) * (Math.PI / 2)) + 1;
  },
  easeOutSine(t) {
    return 1 * Math.sin((t / 1) * (Math.PI / 2));
  },
  easeInOutSine(t) {
    return (-1 / 2) * (Math.cos((Math.PI * t) / 1) - 1);
  },
  easeInExpo(t) {
    return t === 0 ? 1 : 1 * Math.pow(2, 10 * (t / 1 - 1));
  },
  easeOutExpo(t) {
    return t === 1 ? 1 : 1 * (-Math.pow(2, (-10 * t) / 1) + 1);
  },
  easeInOutExpo(t) {
    if (t === 0) return 0;
    if (t === 1) return 1;
    if ((t /= 1 / 2) < 1) return (1 / 2) * Math.pow(2, 10 * (t - 1));
    return (1 / 2) * (-Math.pow(2, -10 * --t) + 2);
  },
  easeInCirc(t) {
    return t >= 1 ? t : -1 * (Math.sqrt(1 - (t /= 1) * t) - 1);
  },
  easeOutCirc(t) {
    return 1 * Math.sqrt(1 - (t = t / 1 - 1) * t);
  },
  easeInOutCirc(t) {
    return (t /= 1 / 2) < 1
      ? (-1 / 2) * (Math.sqrt(1 - t * t) - 1)
      : (1 / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1);
  },
  easeInElastic(t) {
    let s = 1.70158;
    let p = 0;
    let a = 1;
    if (t === 0) return 0;
    if ((t /= 1) == 1) return 1;
    if (!p) p = 1 * 0.3;
    if (a < Math.abs(1)) {
      a = 1;
      s = p / 4;
    } else s = (p / (2 * Math.PI)) * Math.asin(1 / a);
    return -(
      a *
      Math.pow(2, 10 * (t -= 1)) *
      Math.sin(((t * 1 - s) * (2 * Math.PI)) / p)
    );
  },
  easeOutElastic(t) {
    let s = 1.70158;
    let p = 0;
    let a = 1;
    if (t === 0) return 0;
    if ((t /= 1) == 1) return 1;
    if (!p) p = 1 * 0.3;
    if (a < Math.abs(1)) {
      a = 1;
      s = p / 4;
    } else s = (p / (2 * Math.PI)) * Math.asin(1 / a);
    return (
      a * Math.pow(2, -10 * t) * Math.sin(((t * 1 - s) * (2 * Math.PI)) / p) + 1
    );
  },
  easeInOutElastic(t) {
    let s = 1.70158;
    let p = 0;
    let a = 1;
    if (t === 0) return 0;
    if ((t /= 1 / 2) == 2) return 1;
    if (!p) p = 1 * (0.3 * 1.5);
    if (a < Math.abs(1)) {
      a = 1;
      s = p / 4;
    } else s = (p / (2 * Math.PI)) * Math.asin(1 / a);
    if (t < 1)
      return (
        -0.5 *
        (a *
          Math.pow(2, 10 * (t -= 1)) *
          Math.sin(((t * 1 - s) * (2 * Math.PI)) / p))
      );
    return (
      a *
        Math.pow(2, -10 * (t -= 1)) *
        Math.sin(((t * 1 - s) * (2 * Math.PI)) / p) *
        0.5 +
      1
    );
  },
  easeInBack: function (t) {
    const s = 1.70158;
    return 1 * (t /= 1) * t * ((s + 1) * t - s);
  },
  easeOutBack(t) {
    const s = 1.70158;
    return 1 * ((t = t / 1 - 1) * t * ((s + 1) * t + s) + 1);
  },
  easeInOutBack(t) {
    let s = 1.70158;
    if ((t /= 1 / 2) < 1)
      return (1 / 2) * (t * t * (((s *= 1.525) + 1) * t - s));
    return (1 / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2);
  },
  easeInBounce(t) {
    return 1 - easingEffects.easeOutBounce(1 - t);
  },
  easeOutBounce(t) {
    if ((t /= 1) < 1 / 2.75) {
      return 1 * (7.5625 * t * t);
    } else if (t < 2 / 2.75) {
      return 1 * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75);
    } else if (t < 2.5 / 2.75) {
      return 1 * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375);
    } else {
      return 1 * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375);
    }
  },
  easeInOutBounce(t) {
    if (t < 1 / 2) return easingEffects.easeInBounce(t * 2) * 0.5;
    return easingEffects.easeOutBounce(t * 2 - 1) * 0.5 + 1 * 0.5;
  },
};

const cookiesToRemove = [];

export function addCookieToGarbage(cookie) {
  cookiesToRemove.push(cookie);
}

export function cookieRemover() {
  for (let i = 0; i < cookiesToRemove.length; i++) {
    docCookies?.removeItem(cookiesToRemove[i]);
  }
}

//Définition des styles utilisés pour les messages de la console
export const traceStyles = {
  chat: {
    style:
      "background-color:rgba(92, 197, 105, 0.3); color:#222222; padding-left:1em; padding-right:1em; border:1px solid #d93030;",
    prefix: "Debug Chat  =>  ",
  },

  debug: {
    style: "font-size:14px; color:red;",
    prefix: "Debug  =>  ",
  },
  session: {
    style:
      "background-color: #E4E4E4; color:#222222; padding-left:1em; padding-right:1em; border:1px solid #FF6C00;",
    prefix: "Debug Session storage  =>  ",
  },
  erreur: {
    style:
      "background-color:#d93030; color : #FFF; font-weight:bold; padding-left:1em; padding-right:1em;",
    prefix: "ERREUR => ",
  },
};

/**
 * Charge le fichier handlebar
 *   {object} $container_script L'élément ou le template non compilé sera inséré
 *   {string} filename          Le nom du fichier à charger
 *   {object} json              Le json s'il est inclus dans la page
 *   {object} filters           Les filtres
 *   {object} Module            Le Module qui appelle la fonction, par exemple udc, messagerie, virement, etc...
 */
export function loadHandlebarHtml(
  $container_script,
  filename,
  json,
  filters,
  Module,
  jsonFilter
) {
  if ($container_script.length == 0) {
    return;
  }
  const deferred = $.Deferred();

  $.ajax({
    url: filename,
    dataType: "html",
  }).done(function (data) {
    $container_script.html(data);
    let $handlebar_script = $container_script.find(".handlebar-script"),
      count = 0;

    $handlebar_script.each(function (index) {
      const $template = $(this);
      initHandlebar(
        $template,
        json,
        filters,
        function (data, onUpdateFn) {
          count += 1;
          // Si c'est le dernier template a être compilé on resolve le Deferred
          if (count == $handlebar_script.length) {
            deferred.resolve(data);
          }
        },
        Module,
        jsonFilter
      );
    });
  });
  return deferred;
}

export class Filters {
  /**
   * Filters object
   */
  constructor($buttons_filters) {
    this.buttons = $buttons_filters;
  }

  add() {
    const self = this;
    self.buttons.on("click.filters", function () {
      const $current_button = $(this),
        selector_to_show = $(this).data("filter-show"),
        $element_to_show = $(selector_to_show),
        parent = $(this).data("parent"),
        $parent = $(parent);

      // Set the parent
      self.parent = $parent;

      if ($current_button.hasClass("active")) {
        self.reset();
      } else {
        self.buttons.removeClass("active");
        $current_button.addClass("active");
        $parent.addClass("hidden");
        $element_to_show.removeClass("hidden");
      }
    });
  }

  reset() {
    this.buttons.removeClass("active");
    if (this.parent !== undefined) {
      this.parent.removeClass("hidden");
    }
  }
}

/**
 * init Handlebar templates
 */
export function initHandlebar(
  $current_element,
  json,
  filters,
  callback,
  Module,
  jsonFilter
) {
  if ($current_element.length == 0) {
    return;
  }

  let json_path = "/rsc/contrib/dev/json/",
    source = $current_element.html(),
    template = Handlebars.compile(source),
    filename = $current_element.data("filename"),
    placeholder_elem_selector = $current_element.data("template-placeholder"),
    $elem_placeholder = $(placeholder_elem_selector),
    search_elem_selector = $current_element.data("search-field"),
    $search_field = $(search_elem_selector),
    messages = {
      no_results: "Pas de résultat",
      error: "Une erreur s'est produite",
      results_number_single: "résultat trouvé",
      results_number_multiple: "résultats trouvés",
    },
    keys = $current_element.data("keys"),
    search_in = $current_element.data("search-in"),
    total_results_selector = $current_element.data("total-results"),
    $total_results_placeholder = $(total_results_selector),
    sort_by_selector = $current_element.data("sort-by"),
    $sort_by_elem = $(sort_by_selector),
    insert_into = $current_element.data("insert-into"),
    $insert_into = $(insert_into),
    onUpdateFn = $current_element.data("on-update"),
    data = {};

  /**
   * Init template handlebar avec tous les fonctionnalités (recherche, filtres, tri)
   *   {object} data Les donnés du json
   */
  function initTemplate(data, onUpdateFn) {
    if ($sort_by_elem.length > 0) {
      if ($sort_by_elem.find(".active").data("value") !== undefined) {
      } else {
        $.each(keys, function (idx, elem) {
          sortJson(data[elem]);
        });
      }
      if ($sort_by_elem.find(".active").data("value")) {
        $sort_by_elem.on("click", "li", function () {
          sortJson(
            getBodyData(data, keys),
            $sort_by_elem.find(".active").data("direction")
          );
          updateTemplate($elem_placeholder, template, data);
          Module?.tri?.();
        });
      } else {
        $sort_by_elem.on("change.sort-by", function () {
          $.each(keys, function (idx, elem) {
            sortJson(data[elem]);
          });
          updateTemplate($elem_placeholder, template, data);
          const tmpFN = new Function(onUpdateFn);
          tmpFN();
          Module?.tri?.();
        });
      }
    }
    // console.log($elem_placeholder, template, data )
    updateTemplate($elem_placeholder, template, data);

    if (filters) {
      filters.add();
    }
    if ($search_field.length > 0) {
      addKeyUp($elem_placeholder, template, data, $search_field, onUpdateFn);
    }

    /**
     * Init les options, validations, évènements sur les nouveaux éléments créés
     */
    $elem_placeholder.find(".validate-form").each(function (index, elem) {
      const $elem = $(elem);

      $elem.validate(FormHelper?.form_validator_config);
      FormHelper?.resetInput($elem);
    });

    HelpText.init($elem_placeholder);
    try {
      if (Module?.advancedSearch !== undefined) {
        Module?.advancedSearch?.(
          $elem_placeholder,
          template,
          data,
          $("#recherche-avancee-form"),
          keys
        );
      }

      callback(data);
    } catch (e) {}
  }

  if (json) {
    data = json;
    initTemplate(data, onUpdateFn);
  } else {
    $.getJSON(json_path + filename, function (data, status) {
      if (typeof jsonFilter != "undefined") {
        initTemplate(eval("data" + jsonFilter), onUpdateFn);
      } else {
        initTemplate(data, onUpdateFn);
      }
    });
  }
  function getBodyData(array, parts) {
    let new_array = array;
    for (let i = 0; i < parts.length; i++) {
      new_array = new_array[parts[i]];
    }
    return new_array;
  }

  /**
   * Tri les résultats
   *   {array} data
   *   {string} direction 'up' ou 'down'
   */
  function sortJson(data, direction) {
    const sort_by = $sort_by_elem.find(".active").data("value")
      ? $sort_by_elem.find(".active").data("value")
      : $sort_by_elem.val();

    // Reset la valeur new_group pour grouper les virements favoris par compte
    $.each(data, function (index, value) {
      value["new_group"] = false;
    });
    switch (sort_by) {
      case "date_virement":
      case "date":
      case "dateReception":
      case "date_opposition":
      case "date_fin":
      default:
        data.sort(sortByDate(sort_by));
        if (direction == "up") {
          data.reverse();
        }
        break;
      case "montant":
      case "statut_code":
        data.sort(sortByNumber(sort_by));
        break;
      case "nom":
      case "numero":
      case "motif":
      case "libelle":
      case "libelle_compte_receveur":
      case "statut":
      case "type":
      case "objet":
      case "compte":
        data.sort(sortByString(sort_by));
        if (sort_by === "numero") {
          let last_index = 0;

          $.each(data, function (index, virement) {
            if (index == 0) {
              virement["new_group"] = true;
            }
            if (index > 0) {
              // Si le numéro n'est pas égal à celui d'avant, c'est un nouveau numéro
              if (virement["numero"] != data[last_index]["numero"]) {
                // new_numero_array_index.push( index );
                last_index = index;
                virement["new_group"] = true;
              }
            }
          });
        }
        if (direction == "up") {
          data.reverse();
        }
        break;
    }
  }

  /**
   * Mise à jour du template handlebar avec les nouvelles données
   * par exemple trié, ou recherché
   *   {object} $elem_placeholder jQuery object élément dans lesquels on réinsère les données
   *   {function} template        Handlebar fonction permettant de compiler le template
   *   {object} data              Les données à insérer dans le template
   */
  function updateTemplate($elem_placeholder, template, data) {
    //console.log(0)
    if ($insert_into.length == 0) {
      $elem_placeholder.html(template(data));
      //console.log(1)
    } else {
      $insert_into.append(template(data));
    }
  }

  function noResultsMessage($elem_placeholder) {
    $elem_placeholder.html(messages.no_results);
  }

  function errorMessage($elem_placeholder) {
    $elem_placeholder.html(messages.error);
  }

  const delay = (function () {
    let timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  function addKeyUp(
    $elem_placeholder,
    template,
    data,
    $search_field,
    onUpdateFn
  ) {
    const results = {};

    $search_field.on("keyup.search", function (e) {
      const $this = $(this),
        $parent = $this.parent(".search");

      $parent.addClass("is-searching");

      delay(function () {
        if ($this.val().length === 0) {
          updateTemplate($elem_placeholder, template, data);
          const tmpFN = new Function(onUpdateFn);
          tmpFN();
          hideTotalResults();
          $parent.removeClass("is-searching");
          $search_field.trigger("search-done");
          return;
        }

        const value_array = $this.val().split(" ");
        let formatted_value_array;

        formatted_value_array = $.map(value_array, function (str) {
          const formatted_str = searchFormat(str);
          if (formatted_str != "") {
            return formatted_str;
          }
        });

        search(data, formatted_value_array, results, onUpdateFn);

        if (filters !== undefined && filters != "") {
          filters.reset();
        }

        $parent.removeClass("is-searching");
      }, 400);
    });
  }

  /**
   * Fonction de recherche
   *   {object} data     Les données brutes du json
   *   {array} formatted_value_array Un tableau de données splités sur les espaces
   *   {object} results  Les résultats de la recherche
   */
  function search(data, formatted_value_array, results, onUpdateFn) {
    let total_results = 0;
    const new_results = {};
    // Boucle sur les clés du json sur celles qu'on affichent (e.g: [beneficiaires_sepa, beneficiaires_international])
    $.each(keys, function (index, key) {
      const json_rows = data[key];

      // Boucle sur les lignes du json, et check si elles correspondent à la recherche
      results[key] = $.map(json_rows, function (value) {
        // Création d'un tableau contenant les valeurs du json à comparer avec les mots tapés
        const values_to_match = [];

        $.each(search_in, function (idx, element) {
          if (value[element] === undefined) {
            return;
          }
          values_to_match.push(value[element]);
        });

        if (checkValue(formatted_value_array, values_to_match)) {
          total_results++;
          return value;
        }
      });

      if (key.indexOf(".") > -1) {
        const key_array = key.split(".");
        new_results[key_array[0]] = {};
        new_results[key_array[0]][key_array[1]] = results;
      }
    });
    if (!$.isEmptyObject(new_results)) {
      results = new_results;
    }
    updateTemplate($elem_placeholder, template, results);
    const tmpFN = new Function(onUpdateFn);
    tmpFN();
    displayTotalResults(total_results);
    $search_field.trigger("search-done");
  }

  /**
   * Affiche les messages selon le nombre de résultats
   *   {int} total_results   Le nombre de résulats total
   */
  function displayTotalResults(total_results) {
    $total_results_placeholder.removeClass("hidden");
    if (total_results == 0) {
      $total_results_placeholder.html(messages.no_results);
    }
    if (total_results == 1) {
      $total_results_placeholder.html(
        total_results + " " + messages.results_number_single
      );
    }
    if (total_results > 1) {
      $total_results_placeholder.html(
        total_results + " " + messages.results_number_multiple
      );
    }
  }

  /**
   * Cache le nombre de résultats
   */
  function hideTotalResults() {
    $total_results_placeholder.addClass("hidden");
  }

  ShowHideHelper.togglePlusMoins(".list-fav li .main-row");
}

export const preventPasteOnConfirm = {
  init: function () {
    $("[equalto]").each(function () {
      const $me = $(this);

      $me.on("paste", function (e) {
        e.preventDefault();
      });
    });
  },
};

export const canvasBuilder = {
  charts: [],

  init() {
    if (ieVersion !== false && ieVersion <= 8) {
      return;
    }
    $(".js-chart-pie")
      .each(function () {
        let w = $(this).width(),
          h = $(this).height(),
          currentPourc = $(this).data("pourcentage");

        if (isNaN(currentPourc)) {
          currentPourc = 0;
        }

        $(this).html(`<canvas width="${w}" height="${h}"></canvas>`);

        const newChart = new Chart(
          $(this).children("canvas").get(0).getContext("2d")
        )?.chartPieWithPourc({ pourc: currentPourc });
        canvasBuilder.charts.push(newChart);
      })
      .removeClass("js-chart-pie")
      .addClass("js-chart-pie-built");
  },
};

function buildCSSLoader(el) {
  let code = `<div class="spinner">
  <div class="spinner-container container1">
    <div class="circle1"></div>
    <div class="circle2"></div>
    <div class="circle3"></div>
    <div class="circle4"></div>
  </div>
  <div class="spinner-container container2">
    <div class="circle1"></div>
    <div class="circle2"></div>
    <div class="circle3"></div>
    <div class="circle4"></div>
  </div>
  <div class="spinner-container container3">
    <div class="circle1"></div>
    <div class="circle2"></div>
    <div class="circle3"></div>
    <div class="circle4"></div>
  </div>
</div>`;

  el.append(code).addClass("hasCSSLoader");
}

function ieVersion() {
  let rv = false;
  if (navigator.appName === "Microsoft Internet Explorer") {
    const ua = navigator.userAgent;
    const re = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})");
    if (re.exec(ua) != null) {
      rv = parseFloat(RegExp.$1);
    }
  } else if (navigator.appName === "Netscape") {
    const ua = navigator.userAgent;
    const re = new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})");
    if (re.exec(ua) != null) {
      rv = parseFloat(RegExp.$1);
    }
  }

  return rv;
}

export function buildCanvasLoader(el, params) {
  if (ieVersion !== false && ieVersion <= 8) {
    return;
  }
  let w = el.width();
  let h = el.height();
  let margesL = 0;
  let margesH = 0;

  if (w > h) {
    margesL = (w - h) / 2;
  } else if (h > w) {
    margesH = (h - w) / 2;
  }

  let defaultParams = {
    fps: 30,
    color: "#FFF",
    dotNumber: 10,
    dotWidth: 15,
    density: 2,
    persistence: 0.3,
    speed: 1,
    ease: "linear",
    trailMode: "linear",
    type: "dots",
    taillength: 0.9,
    attenuation: 1,
    precision: 50,
    linewidth: 10,
    bgcolor: "rgba(255, 255, 255, 0)",
    flowerPetalWidth: 10,
    flowerBorder: 2,
    flowerEmptyCenter: 40,
    flowerHighlightColor: "#ce1e43",
  };

  defaultParams = $.extend(defaultParams, params);

  el.append(`<canvas width="${w}" height="${h}"></canvas>`)
    .children("canvas")
    .css({
      width: w + "px",
      height: h + "px",
    });

  let canvas = el.children("canvas");
  let CTX = canvas.get(0).getContext("2d");
  let counter = 1;
  let color =
    defaultParams.color.match(/^rgba/) !== null
      ? parseRGBA(defaultParams.color)
      : getRGB(defaultParams.color);
  let start = null;
  let maxStep = (defaultParams.fps * 1) / defaultParams.speed;

  requestAnimationFrame(paint);
  function paint(ts) {
    if (start == null) {
      start = ts;
    }

    const progress = ts - start;

    if (progress > 1000 / defaultParams.fps) {
      start = ts;
      CTX.clearRect(0, 0, w, h);

      let rayon =
        defaultParams.type === "flower"
          ? margesL > margesH
            ? (w - margesL * 2) / 2
            : (h - margesH * 2) / 2
          : margesL > margesH
          ? (w - margesL * 2 - defaultParams.dotWidth * 2) / 2
          : (h - margesH * 2 - defaultParams.dotWidth * 2) / 2;
      let stepDecimal = (counter / maxStep) * defaultParams.speed;
      let ease = easingEffects[defaultParams.ease](stepDecimal);
      let mainAngle = 2 * Math.PI * ease - Math.PI / 2;
      let decalageAngle;

      if (defaultParams.type === "tail") {
        CTX.beginPath();
        CTX.strokeStyle = defaultParams.bgcolor;
        CTX.lineWidth = defaultParams.linewidth - 1;
        CTX.arc(w / 2, h / 2, rayon, 0, 2 * Math.PI);
        CTX.stroke();
        CTX.closePath();

        const itemLength =
            (2 * Math.PI * defaultParams.taillength) / defaultParams.precision,
          opacitySteps = 1 / defaultParams.precision;

        for (let i = 0; i < defaultParams.precision; i++) {
          const startAngle = mainAngle - (i + 1) * itemLength;

          let tmpColor;
          if (color.length > 3) {
            let atten = color[3] - i * opacitySteps * defaultParams.attenuation;
            if (atten < 0) {
              atten = 0;
            }
            tmpColor =
              "rgba(" +
              color[0] +
              ", " +
              color[1] +
              ", " +
              color[2] +
              ", " +
              atten +
              ")";
          } else {
            tmpColor = makeRGBAColor(
              color,
              1 - i * opacitySteps * defaultParams.attenuation
            );
          }

          CTX.beginPath();
          CTX.strokeStyle = tmpColor;
          CTX.lineWidth = defaultParams.linewidth;
          CTX.arc(
            w / 2,
            h / 2,
            rayon,
            startAngle + itemLength / 2,
            startAngle + itemLength * 1.5
          );
          CTX.stroke();

          CTX.closePath();
        }
      } else if (defaultParams.type === "flower") {
        const flowerBorderRadius = Math.round(
            defaultParams.flowerPetalWidth / 2
          ),
          flowerRayon = rayon - defaultParams.flowerEmptyCenter / 2,
          currentPos = Math.floor(8 * ease);

        for (let i = 0; i < 8; i++) {
          const currentAngle = (i * 2 * Math.PI) / 8 - ((2 * Math.PI) / 8) * 2;
          CTX.setTransform(1, 0, 0, 1, 0, 0); //Reset transformations

          CTX.translate(w / 2, h / 2);
          CTX.rotate(currentAngle);

          //Forme extérieure
          CTX.fillStyle =
            currentPos === i
              ? defaultParams.flowerHighlightColor
              : defaultParams.bgcolor;
          CTX.beginPath();

          CTX.moveTo(
            defaultParams.flowerEmptyCenter / 2 + flowerBorderRadius,
            -defaultParams.flowerPetalWidth / 2
          );
          CTX.lineTo(
            defaultParams.flowerEmptyCenter / 2 +
              flowerRayon -
              flowerBorderRadius,
            -defaultParams.flowerPetalWidth / 2
          );
          CTX.arcTo(
            defaultParams.flowerEmptyCenter / 2 + flowerRayon,
            -defaultParams.flowerPetalWidth / 2,
            defaultParams.flowerEmptyCenter / 2 + flowerRayon,
            flowerBorderRadius - defaultParams.flowerPetalWidth / 2,
            flowerBorderRadius
          );
          CTX.lineTo(
            defaultParams.flowerEmptyCenter / 2 + flowerRayon,
            defaultParams.flowerPetalWidth -
              flowerBorderRadius -
              defaultParams.flowerPetalWidth / 2
          );
          CTX.arcTo(
            defaultParams.flowerEmptyCenter / 2 + flowerRayon,
            defaultParams.flowerPetalWidth - defaultParams.flowerPetalWidth / 2,
            defaultParams.flowerEmptyCenter / 2 +
              flowerRayon -
              flowerBorderRadius,
            defaultParams.flowerPetalWidth / 2,
            flowerBorderRadius
          );
          CTX.lineTo(
            defaultParams.flowerEmptyCenter / 2 + flowerBorderRadius,
            defaultParams.flowerPetalWidth / 2
          );
          CTX.arcTo(
            defaultParams.flowerEmptyCenter / 2,
            defaultParams.flowerPetalWidth / 2,
            defaultParams.flowerEmptyCenter / 2,
            defaultParams.flowerPetalWidth / 2 - flowerBorderRadius,
            flowerBorderRadius
          );
          CTX.lineTo(
            defaultParams.flowerEmptyCenter / 2,
            flowerBorderRadius - defaultParams.flowerPetalWidth / 2
          );
          CTX.arcTo(
            defaultParams.flowerEmptyCenter / 2,
            -defaultParams.flowerPetalWidth / 2,
            defaultParams.flowerEmptyCenter / 2 + flowerBorderRadius,
            -defaultParams.flowerPetalWidth / 2,
            flowerBorderRadius
          );

          CTX.closePath();
          CTX.fill();

          //Forme intérieure
          if (currentPos !== i) {
            const newWidth =
              defaultParams.flowerPetalWidth - defaultParams.flowerBorder * 2;
            const newBorderRadius = Math.round(newWidth / 2);
            const decalage = defaultParams.flowerBorder;
            const newRayon = flowerRayon - decalage * 2;

            CTX.fillStyle = defaultParams.color;
            CTX.beginPath();

            CTX.moveTo(
              defaultParams.flowerEmptyCenter / 2 + decalage + newBorderRadius,
              decalage - defaultParams.flowerPetalWidth / 2
            );
            CTX.lineTo(
              defaultParams.flowerEmptyCenter / 2 +
                decalage +
                newRayon -
                newBorderRadius,
              decalage - defaultParams.flowerPetalWidth / 2
            );
            CTX.arcTo(
              defaultParams.flowerEmptyCenter / 2 + decalage + newRayon,
              decalage - defaultParams.flowerPetalWidth / 2,
              defaultParams.flowerEmptyCenter / 2 + decalage + newRayon,
              decalage + newBorderRadius - defaultParams.flowerPetalWidth / 2,
              newBorderRadius
            );
            CTX.lineTo(
              defaultParams.flowerEmptyCenter / 2 + decalage + newRayon,
              decalage +
                newWidth -
                newBorderRadius -
                defaultParams.flowerPetalWidth / 2
            );
            CTX.arcTo(
              defaultParams.flowerEmptyCenter / 2 + decalage + newRayon,
              decalage + newWidth - defaultParams.flowerPetalWidth / 2,
              defaultParams.flowerEmptyCenter / 2 +
                decalage +
                newRayon -
                newBorderRadius,
              decalage + newWidth - defaultParams.flowerPetalWidth / 2,
              newBorderRadius
            );
            CTX.lineTo(
              defaultParams.flowerEmptyCenter / 2 + decalage + newBorderRadius,
              decalage + newWidth - defaultParams.flowerPetalWidth / 2
            );
            CTX.arcTo(
              defaultParams.flowerEmptyCenter / 2 + decalage,
              decalage + newWidth - defaultParams.flowerPetalWidth / 2,
              defaultParams.flowerEmptyCenter / 2 + decalage,
              decalage +
                newWidth -
                newBorderRadius -
                defaultParams.flowerPetalWidth / 2,
              newBorderRadius
            );
            CTX.lineTo(
              defaultParams.flowerEmptyCenter / 2 + decalage,
              decalage + newBorderRadius - defaultParams.flowerPetalWidth / 2
            );
            CTX.arcTo(
              defaultParams.flowerEmptyCenter / 2 + decalage,
              decalage - defaultParams.flowerPetalWidth / 2,
              defaultParams.flowerEmptyCenter / 2 + decalage + newBorderRadius,
              decalage - defaultParams.flowerPetalWidth / 2,
              newBorderRadius
            );

            CTX.closePath();
            CTX.fill();
          }
        }
        CTX.setTransform(1, 0, 0, 1, 0, 0);
      } else {
        for (let i = 0; i < defaultParams.dotNumber; i++) {
          if (defaultParams.trailMode === "linear") {
            decalageAngle =
              (i * 2 * Math.PI) /
              defaultParams.dotNumber /
              defaultParams.density;
          } else if (defaultParams.trailMode === "easeInOut") {
            decalageAngle =
              stepDecimal < 0.5
                ? ((i * 2 * Math.PI) /
                    defaultParams.dotNumber /
                    defaultParams.density) *
                  stepDecimal
                : ((i * 2 * Math.PI) /
                    defaultParams.dotNumber /
                    defaultParams.density) *
                  (1 - stepDecimal);
          } else if (defaultParams.trailMode === "followGlobalEase") {
            decalageAngle =
              stepDecimal < 0.5
                ? ((i * 2 * Math.PI) /
                    defaultParams.dotNumber /
                    defaultParams.density) *
                  ease
                : ((i * 2 * Math.PI) /
                    defaultParams.dotNumber /
                    defaultParams.density) *
                  (1 - ease);
          }

          const posX = w / 2 + Math.cos(mainAngle - decalageAngle) * rayon;
          const posY = h / 2 + Math.sin(mainAngle - decalageAngle) * rayon;

          CTX.beginPath();
          CTX.fillStyle = makeRGBAColor(
            color,
            1 -
              (1 / defaultParams.dotNumber) * i +
              1 * defaultParams.persistence
          );
          CTX.arc(
            posX,
            posY,
            (defaultParams.dotWidth / defaultParams.dotNumber) *
              (defaultParams.dotNumber - i),
            0,
            2 * Math.PI
          );
          CTX.fill();

          CTX.closePath();
        }
      }

      counter++;
      if (stepDecimal >= 1) {
        counter = 1;
      }
    }

    requestAnimationFrame(paint);
  }
}

export function formatNumber(nbr, digits) {
  let prefix = "";
  if (String(nbr).substr(0, 1) == "-") {
    nbr = String(nbr).substr(1);
    prefix = "-";
  }

  if (digits == 0) {
    return Math.round(nbr);
  }

  let tempNbr = Math.round(nbr * Math.pow(10, digits)) / Math.pow(10, digits);
  const nbrAr = String(tempNbr).split(".");
  if (nbrAr.length < 2) {
    tempNbr = String(nbrAr[0]) + String(",00");
    return prefix + replaceDot(tempNbr);
  } else if (nbrAr[1].length < 2) {
    tempNbr = String(tempNbr) + String("0");
    return prefix + replaceDot(tempNbr);
  } else {
    return prefix + replaceDot(tempNbr);
  }
}

export function addThousandsSep(nbr, sep) {
  let prefix = "";
  if (String(nbr).substr(0, 1) == "-") {
    nbr = String(nbr).substr(1);
    prefix = "-";
  }

  const _nbrTab = String(nbr).split(",");
  const _sep = sep != null ? sep : ".";

  if (_nbrTab[0].length > 3) {
    const revInt = String(_nbrTab[0]).stringReverse();
    let returnNbr = "";
    const maxLoops = Math.ceil(_nbrTab[0].length / 3);
    for (let i = 0; i <= maxLoops; i++) {
      returnNbr += revInt.substr(0 + i * 3, 3);
      if (i < maxLoops - 1) {
        returnNbr += _sep;
      }
    }
    if (_nbrTab.length > 1) {
      return prefix + String(returnNbr).stringReverse() + "," + _nbrTab[1];
    } else {
      return prefix + String(returnNbr).stringReverse();
    }
  } else {
    return prefix + nbr;
  }
}

export function replaceDot(nbr) {
  return String(nbr).replace(".", ",");
}

export function replaceComa(nbr) {
  return String(nbr).replace(",", ".");
}

export function getBreadcrumb() {
  let current_url = document.URL,
    module = current_url.substr(current_url.lastIndexOf("/") + 1);

  if (module == "") {
    const current_url_parts = current_url.split("/");
    module = current_url_parts[current_url_parts.length - 2];
  }

  return module;
}

/**
 * Paramètre url sous forme d'ancre
 *  {string} name Nom du paramètre
 **/
export function getUrlAnchor(sParam) {
  const pattern = new RegExp("#" + sParam + "=(.*)");
  const match = window.location.href.match(pattern);

  if (match == null) {
    return null;
  }

  if (match.length > 0) {
    return match[1];
  }

  return null;
}
