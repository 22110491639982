import {DeviceSize} from "../HelperDeviceSize.mjs";
import {$body} from "../../coreUtils/index.mjs";
import {FormHelper} from "../../plumbing/circularReferencesSolver.mjs";

export const ShowHideHelper = {
  /**
   * KEY_CODES constante pour les codes des boutons clavier
   */
  KEY_CODES: { ENTER: 13, SPACE: 32, SHIFT: 9, UNDEFINED: "undefined" },
  /**
   * Init toutes les fonction
   */
  init() {
    this.dataToggleSection();
    this.dataCheckboxShowSection();
    this.dataRadioButtonSection();
    this.dataShowHideSection();
    this.dataShowHideOverSection();
    this.dataShowHideSectionSlider();
    this.dataSlideOpenSection();
    this.dataMakeActiveInactiveSection();
    this.dataToggleActive();
    this.dataMakeEnabled();
    this.dataGroupMakeEnabled();
    this.dataSendValueToInput();
    this.dataResetForm();
    this.identToggle();
    this.callbackMessage();
    this.fixBarreRecherche(); // position sticky
    this.fixOngletTop(); // position sticky
    this.disableNanoClick();
    this.initShowHideRules();
    this.dataShowHideViewer();
    this.autoNanoScroller();
  },
  /**
   * Permet de afficher/cacher un ou des éléments au clic
   */
  dataToggleSection() {
    $("[data-toggle-section]").on("click.toggle-section", function () {
      const $this = $(this);

      const iconCarretFiltre = function ($elt) {
        const iconCarretFilter = (
          $this.data("icon-carret-filter") || ""
        ).trim();

        return iconCarretFilter ? $elt.filter(iconCarretFilter) : $elt;
      };

      const $iconCarretDown = iconCarretFiltre($this.find(".icon-carret-down"));
      const $iconCarretUp = iconCarretFiltre($this.find(".icon-carret-up"));

      toggle($(this));
      if ($iconCarretDown.length > 0) {
        $iconCarretDown
          .removeClass("icon-carret-down")
          .addClass("icon-carret-up");
      } else if ($iconCarretUp.length > 0) {
        $iconCarretUp
          .removeClass("icon-carret-up")
          .addClass("icon-carret-down");
      }

      if ($(this).hasClass("toggled")) {
        $(this).removeClass("toggled");
      } else {
        $(this).addClass("toggled");
      }
    });

    function toggle($elem) {
      const elem_id = $elem.data("toggle-section");
      const $elem_to_toggle = $(elem_id);

      if ($elem_to_toggle.hasClass("hidden")) {
        $elem_to_toggle.removeClass("hidden");
      } else {
        $elem_to_toggle.addClass("hidden");
      }
    }
  },
  dataToggleSectionCtx($ctx) {
    // contextualisation
    $("[data-toggle-section]", $ctx).on("click.toggle-section", function () {
      toggle($(this));
      if ($(this).find(".icon-carret-down").length > 0) {
        $(this)
          .find(".icon-carret-down")
          .removeClass("icon-carret-down")
          .addClass("icon-carret-up");
      } else if ($(this).find(".icon-carret-up").length > 0) {
        $(this)
          .find(".icon-carret-up")
          .removeClass("icon-carret-up")
          .addClass("icon-carret-down");
      }

      if ($(this).hasClass("toggled")) {
        $(this).removeClass("toggled");
      } else {
        $(this).addClass("toggled");
      }
    });

    function toggle($elem) {
      const elem_id = $elem.data("toggle-section");
      const $elem_to_toggle = $(elem_id, $ctx);

      if ($elem_to_toggle.hasClass("hidden")) {
        $elem_to_toggle.removeClass("hidden");
      } else {
        $elem_to_toggle.addClass("hidden");
      }
    }
  },
  /**
   * Permet d'ajouter/enlever la class .active sur un ou des éléments au clic
   */
  dataToggleActive() {
    $("[data-toggle-active]").on("click.toggle-active", function () {
      toggle($(this));
    });

    function toggle($elem) {
      const elem_id = $elem.data("toggle-active");
      const $elem_to_toggle = $(elem_id);

      if ($elem_to_toggle.hasClass("active")) {
        $elem_to_toggle.removeClass("active");
      } else {
        $elem_to_toggle.addClass("active");
      }
    }
  },
  /**
   * Permet d'afficher/cacher un ou des éléments en fonction de l'état d'un radio button
   */
  dataRadioButtonSection() {
    $("[data-toggle-radio-section]").on(
      "click.toggle-radio-section",
      function () {
        toggle($(this), $(this).attr("name"));
      }
    );

    function toggle($elem) {
      const elem_id = $elem.data("toggle-radio-section");
      const $elem_to_toggle = $(elem_id);

      if ($elem.prop("checked")) {
        $elem_to_toggle.removeClass("hidden");
      } else {
        $elem_to_toggle.addClass("hidden");
      }
    }

    $("[data-toggle-radio-section]").each(function () {
      const $all_radios = $(`input[name=${$(this).attr("name")}]`);
      const elem_id = $(this).data("toggle-radio-section");
      const $elem_to_toggle = $(elem_id);

      $all_radios.not($(this)).on("click", () => {
        $elem_to_toggle.addClass("hidden");
      });
    });
    // On load
    toggle($("[data-toggle-radio-section]"));
  },
  /**
   * Permet d'afficher/cacher un ou des éléments en fonction de l'état d'une checkbox
   */
  dataCheckboxShowSection() {
    $("[data-toggle-checkbox-section]").on(
      "click.toggle-checkbox-section",
      function () {
        toggle($(this));
      }
    );

    function toggle($elem) {
      const elem_id = $elem.data("toggle-checkbox-section");
      const $elem_to_toggle = $(elem_id);

      if ($elem.prop("checked")) {
        $elem_to_toggle.removeClass("hidden");
      } else {
        $elem_to_toggle.addClass("hidden");
      }
    }

    // On load
    toggle($("[data-toggle-checkbox-section]"));
  },
  /**
   * Permet d'afficher un ou des éléments cibles, et cacher d'autre(s) élément(s) cibles
   */
  dataShowHideSection() {
    $("[data-show-section],[data-hide-section]").on(
      "click.show-hide-section",
      function () {
        const show_elem_selector = $(this).data("show-section");
        const $elem_to_show = $(show_elem_selector);
        const hide_elem_selector = $(this).data("hide-section");
        const $elem_to_hide = $(hide_elem_selector);

        $elem_to_hide.addClass("hidden");
        $elem_to_show.removeClass("hidden");
      }
    );
  },
  dataShowHideSectionSlider() {
    let targetEvent = "mouseenter.show-hide-section";
    if (DeviceSize.getDeviceState() == "mobile" || $(window).width() <= 1024) {
      targetEvent = "click.show-hide-section";
    }
    $("[data-show-section-slider],[data-hide-section-slider]").on(
      targetEvent,
      function (event) {
        event.preventDefault();
        event.stopPropagation();

        const show_elem_selector = $(this).data("show-section-slider");
        const $elem_to_show = $(show_elem_selector);
        const hide_elem_selector = $(this).data("hide-section-slider");
        const $elem_to_hide = $(hide_elem_selector);

        $elem_to_hide.addClass("hidden");
        $elem_to_show.removeClass("hidden").trigger("isVisible");
      }
    );
  },
  dataShowHideOverSection() {
    $("[data-show-section-over],[data-hide-section-over]").on(
      "mouseenter.show-hide-section",
      function () {
        const show_elem_selector = $(this).data("show-section-over");
        const $elem_to_show = $(show_elem_selector);
        const hide_elem_selector = $(this).data("hide-section-over");
        const $elem_to_hide = $(hide_elem_selector);

        $elem_to_hide.addClass("hidden");
        $elem_to_show.removeClass("hidden");
      }
    );
  },
  /**
   * Permet d'afficher un ou des éléments cibles via slideOpen (ouverture progressive vers le bas)
   */
  dataSlideOpenSection() {
    $("[data-slide-section]").on("click.slide-section", function () {
      const show_elem_selector = $(this).data("slide-section");
      const $elem_to_show = $(show_elem_selector);

      $elem_to_show.slideOpen();
    });
  },
  /**
   * initShowHideRules : Met en place des écouteurs sur les champs dont l'état influe sur l'apparition / disparition d'autres champs
   * Cette fonction ne fonctionne que si un json window._showHideRules détaillant les règles de gestion est défini
   */
  initShowHideRules() {
    //le json window._showHideRules a t-il été défini ?
    if (typeof window._showHideRules == "undefined") {
      return;
    }

    //Mise en place d'écouteurs sur les éléments le nécessitant pour les formulaires de la pages actuelle
    $("form").each(function () {
      const _currentName = $(this).attr("name");

      if (typeof window._showHideRules?.[_currentName] != "undefined") {
        for (let prop in window._showHideRules?.[_currentName]) {
          const currentEl = $(`[name="${prop}"]`);

          if (currentEl.length == 0) {
            continue;
          }

          if (currentEl[0]?.tagName?.toLowerCase?.() == "select") {
            currentEl.change(function () {
              ShowHideHelper.checkShowHideRules($(this));
            });
            //On lance le checkShowHideRules seul car les données peuvent être pré-remplies
            ShowHideHelper.checkShowHideRules(currentEl);
          } else if (currentEl[0]?.tagName?.toLowerCase?.() == "input") {
            if (currentEl?.first?.()?.attr?.("type") == "radio") {
              currentEl.on("click", function () {
                const elName = $(this).attr("name");

                ShowHideHelper.checkShowHideRules(
                  $(this).parents("form").find(`[name="${elName}"]:checked`)
                );
              });
              //On lance le checkShowHideRules seul car les données peuvent être pré-remplies
              ShowHideHelper.checkShowHideRules(currentEl.filter(":checked"));
            }
          }
        }
      }
    });
  },
  /**
   * checkSwhoHideRules effectue l'apparition ou la disparition inhérente à l'élément passé suivant le json window._showHideRules
   *   {jquery object} el [l'élément dont l'état a été mis à jour]
   */
  checkShowHideRules(el) {
    if (el.length == 0 || el.val() == null) {
      return;
    }
    const currentType = el.val().toLowerCase();
    const currentFormName = el.parents("form").attr("name");
    const currentElName = el.attr("name");
    for (let prop in window._showHideRules[currentFormName][currentElName]) {
      if (prop == currentType) {
        const form = el.parents("form").first();
        //Quels éléments sont à montrer ?
        if (
          typeof window._showHideRules[currentFormName][currentElName][prop][
            "show"
          ] != "undefined"
        ) {
          const _ar =
            window._showHideRules[currentFormName][currentElName][prop]["show"];
          for (let i = 0; i < _ar.length; i++) {
            $(`body [name="${_ar[i]}"]`).removeClass("hidden");
          }
        }

        //Quels éléments sont à cacher ?
        if (
          typeof window._showHideRules[currentFormName][currentElName][prop][
            "hide"
          ] != "undefined"
        ) {
          const _ar2 =
            window._showHideRules[currentFormName][currentElName][prop]["hide"];
          for (let j = 0; j < _ar2.length; j++) {
            $(`body [name="${_ar2[j]}"]`).addClass("hidden");
          }
        }
      }
    }
  },

  /**
   * Permet d'attribuer la classe "active" ou "inactive" à un élément au click
   */
  dataMakeActiveInactiveSection() {
    $("[data-make-active],[data-make-inactive]").on(
      "click.make-active-inactive-section",
      function () {
        const active_elem_selector = $(this).data("make-active");
        const $elem_to_make_active = $(active_elem_selector);
        const inactive_elem_selector = $(this).data("make-inactive");
        const $elem_to_make_inactive = $(inactive_elem_selector);

        if (active_elem_selector != inactive_elem_selector) {
          $elem_to_make_inactive.removeClass("active");
          $elem_to_make_active.addClass("active");
        } else {
          if ($elem_to_make_active.hasClass("active")) {
            $elem_to_make_active.removeClass("active");
          } else {
            $elem_to_make_active.addClass("active");
          }
        }
      }
    );
  },
  /**
   * Permet de supprimer l'attribut disabled d'un élément au clic
   */
  dataMakeEnabled() {
    $("[data-make-enabled]").on("click.make-enabled-section", function () {
      const $me = $(this);
      const enabled_elem_selector = $(this).data("make-enabled");
      const $elem_to_enable = $(enabled_elem_selector);

      if (
        $me.attr("type") == "checkbox" ||
        $(`#${$me.attr("for")}`).attr("type") == "checkbox"
      ) {
        const delay = setTimeout(function () {
          // console.log( "$(this).is(':checked') = "+$me.is(':checked') );
          // console.log( "$( '#'+$(this).attr('for') ).is(':checked') = "+$( '#'+$me.attr('for') ).is(':checked') );
          if ($me.is(":checked") || $(`#${$me.attr("for")}`).is(":checked")) {
            $elem_to_enable.prop("disabled", false);
          } else {
            $elem_to_enable.prop("disabled", "disabled");
          }
        }, 10);
      } else {
        $elem_to_enable.prop("disabled", false);
      }
    });
  },
  /**
   * Permet de supprimer l'attribut disabled d'un élément au clic si et seulement si un groupe de checkboxes sont toutes cochées
   */
  dataGroupMakeEnabled() {
    $("[data-group-make-enabled]").on(
      "click.group-make-enabled-section",
      function () {
        const $me = $(this);
        const $elsToWatch = $("[data-group-make-enabled]");
        const enabled_elem_selector = $(this).data("group-make-enabled");
        const $elem_to_enable = $(enabled_elem_selector);

        const delay = setTimeout(function () {
          let ok = 1;

          $elsToWatch.each(function () {
            if (
              $(this).attr("type") == "checkbox" ||
              $(`#${$(this).attr("for")}`).attr("type") == "checkbox"
            ) {
              if (
                $(this).is(":checked") ||
                $(`#${$(this).attr("for")}`).is(":checked")
              ) {
              } else {
                ok = 0;
                return;
              }
            }
          });

          if (ok == 1) {
            $elem_to_enable.prop("disabled", false);
          } else {
            $elem_to_enable.prop("disabled", "disabled");
          }
        }, 10);
      }
    );
  },
  /**
   * Permet d'envoyer une valeur correspondant au name de l'élément vers un input
   */
  dataSendValueToInput() {
    $("select[data-send-value]").on("change.send-value", function () {
      const $elementToSendTo = $($(this).data("send-value"));
      if ($elementToSendTo.get(0).tagName.toLowerCase() == "input") {
        $elementToSendTo.val($(this).val());
      } else {
        $elementToSendTo.html($(this).val());
      }

      FormHelper.storeDatasInSession($elementToSendTo);
    });
    $("[data-send-value]:not(select)").on("click.send-value", function () {
      const $elementToSendTo = $($(this).data("send-value"));
      if ($elementToSendTo.get(0).tagName.toLowerCase() == "input") {
        $elementToSendTo.val($(this).attr("name"));
      } else {
        $elementToSendTo.html($(this).attr("name"));
      }

      FormHelper.storeDatasInSession($elementToSendTo);
    });
  },
  /**
   * Permet de vider un formulaire
   */
  dataResetForm() {
    $("[data-reset-form]").on("click.reset-form", function () {
      const form_elem_selector = $(this).data("reset-form");
      const $form_to_reset = $(form_elem_selector);

      $form_to_reset[0].reset();
    });
  },
  identToggle() {
    const $ident_layer = $("#identLayer");
    $ident_layer.on("focus", "#client-nbr", function () {
      $ident_layer.find(".main-content").addClass("no-left-radius");
      $ident_layer.find(".col-aside").show();
    });
  },
  // Ajout au favori
  callbackMessage() {
    const $row = $("[data-show-message]");

    $row.find(".link").on("click.show-popup", () => {
      const selector = $row.data("show-message");
      const $popup = $(selector);
      $popup.removeClass("hidden");
    });
    $(".message-retour-popup")
      .find(".js-close-popup")
      .on("click", function () {
        $(this).parents(".message-retour-popup").addClass("hidden");
        return false;
      });
  },
  // Plus/moins
  togglePlusMoins(clickable_elements, one_only) {
    const KEY_CODES = this.KEY_CODES;
    $body.on(
      "click.toggle-folder-btn keydown.toggle-folder-btn",
      clickable_elements,
      function (e) {
        if (
          typeof e.keyCode !== KEY_CODES.UNDEFINED &&
          e.keyCode !== KEY_CODES.ENTER &&
          e.keyCode !== KEY_CODES.SPACE
        ) {
          return;
        }
        const $li_parent = $(this).parents("li");
        const $detail_vir = $li_parent.find(".js-detail-toggle");
        const $indicateur = $li_parent.find(".folder-btn");

        if ($detail_vir.hasClass("hidden")) {
          $(this).attr("aria-expanded", "true");
          if (one_only) {
            $(".js-detail-toggle").addClass("hidden");
            $(".folder-btn").html('<i class="icon-carret-down"></i>');
          }
          $(clickable_elements).parents("li").removeClass("hidden");
          $detail_vir.removeClass("hidden");
          $indicateur.html('<i class="icon-carret-up"></i>');
          $li_parent.addClass("open");
        } else {
          $(this).attr("aria-expanded", "false");
          $detail_vir.addClass("hidden");
          $indicateur.html('<i class="icon-carret-down"></i>');
          $li_parent.removeClass("open");
        }
        $(".nano").nanoScroller();
      }
    );
  },
  // Fixe barre de recherche
  fixBarreRecherche() {},
  // Fixe onglet
  fixOngletTop() {
    // Ajout de la class .lien-gellule
    if (
      DeviceSize.getDeviceState() == "mobile" &&
      !$(".onglet").eq(0).hasClass("ancres")
    ) {
      $(".onglet").eq(0).addClass("lien-gellule");
    }
  },
  disableNanoClick() {
    $body.on("click", ".nano-pane", () => {
      return false;
    });
  },
  dataShowHideViewer() {
    if ($("[data-show-viewer]").length == 0) {
      return;
    }
    $("[data-show-viewer]").on("click.show-hide-section", function () {
      const show_elem_selector = $(this).data("show-viewer");
      const $elem_to_show = $(show_elem_selector);

      $elem_to_show.removeClass("hidden");
      $(".full-cache").removeClass("hidden");
      $(window).scrollTop(0);
    });
  },
  autoNanoScroller() {
    try {
      $(".nano-auto").nanoScroller();
    } catch (e) {}
  },
};

