/**
 *
 * @param key
 * @param val
 */
export function updateSession(key, val) {
    sessionStorage[key] = JSON.stringify(val);
}

/**
 *
 * @param key
 * @param val
 */
export function updateLocalStorage(key, val) {
    localStorage[key] = JSON.stringify(val);
}

/**
 * If the value of the key is undefined, return null, otherwise return the value of the key
 * @param key - The key to use to store the data in localStorage.
 * @returns The value of the key in localStorage.
 */
export function getFromLocalStorage(key) {
    const _localVal = localStorage[key];
    const localVal = _localVal === "undefined" ? null : _localVal;

    return JSON.parse(localVal || null);
}

/**
 * If the value of the key in sessionStorage is undefined, return null, otherwise return the value of the key in
 * sessionStorage
 * @param key - The key to get the value from.
 * @returns The value of the key in sessionStorage.
 */
export function getFromSessionStorage(key) {
    const _sessionVal = sessionStorage[key];
    const sessionVal = _sessionVal === "undefined" ? null : _sessionVal;

    return JSON.parse(sessionVal || null);
}

/**
 * It deletes the key from localStorage
 * @param key - The key of the item you want to remove from localStorage.
 */
export function removeFromLocal(key) {
    delete localStorage[key];
}

/**
 * It deletes the key from the sessionStorage object
 * @param key - The key of the item you want to remove from the sessionStorage object.
 */
export function removeFromSession(key) {
    delete sessionStorage[key];
}

/**
 * It removes the key from both local and session storage.
 * @param key - The key of the item you want to remove from storage.
 */
export function removeFromStorage(key) {
    removeFromLocal(key);
    removeFromSession(key);
}

export function removeAllSessionStorage() {
    const keys = Object.keys(window.sessionStorage);
    keys.forEach(function (key) {
        removeFromSession(key);
    });
}

export function removeAllLocalStorage() {
    const keys = Object.keys(window.localStorage);
    keys.forEach(function (key) {
        removeFromLocal(key);
    });
}

export function removeAllStorage() {
    removeAllSessionStorage();
    removeAllLocalStorage();
}