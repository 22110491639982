//@ts-nocheck
// ## Variables d'environnements
// La variable `ENVIRONNEMENT` est accessible dans le scope global, peut avoir plusieurs valeurs :
// - 'LOCAL' (quand on est sur localhost)
// - 'INTE' (quand on est sur un url d'inté)
// - 'QUALIF' (quand on est sur un url de qualif)
// - 'PREVIEW' (quand on est sur un url de preview)
// - 'PROD' (quand on est sur un url de prod)
// - 'MACHINE' (quand on est sur un url de machine de prod ex: prod-v-wea1s1-apa1s1.canalnet-pro.bnpparibas.net)
import { $AsyncDomReady, $body } from "./jqVariables.mjs";

import { constantes as CST } from "../constantes.mjs";
import { getFromLocalStorage, getFromSessionStorage } from "./storageTools.mjs";
import { logFactory } from "./debug.mjs";

export let ENVIRONNEMENT = "NO_ENV";
export let ENV = "";
const URL_CHECKER = [
  {
    condition: /(canalnet|hellobank)-(part|pro|bpf)-inte/.test(location.host),
    environement: "INTE",
  },
  {
    condition: /(canalnet|hellobank)-(part|pro|bpf)-qual/.test(
      window.location.host,
    ),
    environement: "QUALIF",
  },
  {
    condition:
      /prev-(.)*.(canalnet|hellobank)-(part|pro|bpf).bnpparibas.net/.test(
        window.location.host,
      ),
    environement: "PREVIEW",
  },
  {
    condition: /(pro.|)(mabanque|hellobank)(pro|privee|)/.test(
      window.location.host,
    ),
    environement: "PROD",
  },
];

//=================================================
const Log = logFactory("environement.mjs");
//=================================================

function cleanUpEnv(env) {
  return (env || "")
    .split("")
    .filter(function (character) {
      return !Number.isInteger(Number(character));
    })
    .join("")
    .toUpperCase();
}

function envBasedOnUrl() {
  const urlCheckerResult = URL_CHECKER.filter(function (element) {
    return element.condition;
  });
  ENVIRONNEMENT = urlCheckerResult?.[0]?.environement ?? "NO_ENV";
}

// ----------------------------------------------------------------  setEnv
if (ENV !== "") {
  ENVIRONNEMENT = cleanUpEnv(ENV);
} else {
  envBasedOnUrl();
}
if (window.location.host.match(/localhost/) !== null) {
  ENVIRONNEMENT = "LOCAL";
}
if (window.location.host.match(/prod/) !== null) {
  ENVIRONNEMENT = "MACHINE";
}
if (/m-service.bnpparibas.net/.test(window.location.host)) {
  ENVIRONNEMENT = "MOBILE";
}

//----------
$AsyncDomReady().then(function () {
  if (ENVIRONNEMENT === "QUALIF") {
    $body.addClass("qualif");
  }

  if (ENVIRONNEMENT === "INTE") {
    $body.addClass("inte");
  }

  const uabis = window.navigator.userAgent;
  const msie = uabis.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    $body.addClass("on-ie").removeClass("not-ie");
  }
});

//===========================================
export const DEBUG = !!(
  getFromSessionStorage(CST.SESSION_KEYS.DEBUG_MODE) === true ||
  getFromLocalStorage(CST.LOCAL_KEYS.DEBUG_MODE) === true ||
  !!document.location.search.split("?").find(function (item) {
    return item.indexOf("debug") >= 0;
  })
);
//===========================================

if (DEBUG) {
  sessionStorage.trace = 1;
  document.cookie =
    'bnpp_sav={"tracing":true}; expires=' +
    new Date(
      new Date().getTime() + 365 * 24 * 60 * 60 * 1000,
    )?.toGMTString?.() +
    "; path=/";
}

/**
 * DESACTIVATION de console.log et console.warn en PROD et PREVIEW
 */
try {
  if (
    (ENVIRONNEMENT === "PROD" || ENVIRONNEMENT === "PREVIEW") &&
    !sessionStorage.trace &&
    window.console
  ) {
    window.console.log = window.console.warn = function () {};
  }
} catch (e) {
  Log.error(e);
}

try {
  const _ENV = localStorage.ENVIRONNEMENT;
  if (DEBUG && _ENV) {
    ENVIRONNEMENT = _ENV;
  }
} catch (error) {
  Log.error({
    errorDebugEnv: error,
  });
}

// disable console.clear because of frequent omissions in the code that make debugging extremely painful
export const deactivateClear = function () {
  this._clear = this.clear;
  this.clear = function () {
    Log.log(
      "Deactivation of 'console.clear' (untimely omissions in the code that make debugging extremely painful ....)",
    );
  };
  Log.log("console.clear' deactivated ...");
}.bind(console);

export const reactivateClear = function () {
  if (typeof this._clear === "function") {
    this.clear = this._clear;
    Log.log("'console.clear' reactivated ...");
  }
}.bind(console);

try {
  Object.assign(console, { deactivateClear, reactivateClear });
  deactivateClear();
} catch (error) {
  Log.error(error);
}

/**
 *
 * @returns {boolean|(function(...[*]=))}
 */
export function isDebug() {
  return DEBUG;
}

/**
 * [surcharge de console.log]
 * @legacy
 * @param message
 * @param style
 */
export function trace(message, style) {
  if (!DEBUG) {
    return;
  }
  if (typeof message == "object") {
    console.log(message);
    return;
  }
  message = String(message);
  if (style != null) {
    if (!message.match(/^%c/)) {
      message = "%c" + message;
    }
    if (typeof style == "object") {
      console.log(
        message.substr(0, 2) + style.prefix + message.substr(2),
        style.style,
      );
    } else {
      console.log(message, style);
    }
  } else {
    console.log(message);
  }
}
