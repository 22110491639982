import $ from "jquery";
import {assert} from "./lowLevelUtils/assert.mjs";
import {isObject} from "./isObject.mjs";

/**
 * It returns the value of the property at the given path in the given object, or null if the property doesn't exist
 * @depracated : use optional chaining instead
 * @param obj - The object to access.
 * @param path - The path to the property you want to access. // a.b.c.d
 * @returns {boolean|string|null}
 */
export function safeAccess(obj, path) {
    const _path = path || "";
    assert(
        typeof path === "string",
        "safeAccess(): Bad Type arguments: path must be a String",
    );
    const paths = _path.split("."); // [ "a", "b", "c", "d"]

    const result = paths.reduce(function (acc, key) {
        const accKey = acc[key];
        return accKey === 0 || accKey === false ? accKey : accKey || {};
    }, obj);

    return result === 0 || result === false
        ? result
        : (isObject(result) &&
            (!$.isEmptyObject(result) || typeof result === "function") &&
            result) ||
        (!isObject(result) && result) ||
        null;
}

/**
 * legacy compatibility alias
 */
export const securePropsAccess = safeAccess;