import { deepFreeze } from "./coreUtils/deepFreeze.mjs";

const DEBUG_MODE = "debugMode";
const USE_DEBUG_INFOCLIENT_STUB = "USE_DEBUG_INFOCLIENT_STUB";
const NOAEM_REDIRECT_FF = "NOAEM_REDIRECT_FF";
const CUSTOMER_DEMANDS_SESSION_KEY = "CUSTOMER_DEMANDS";
//------------------------------------------------
export const constantes = deepFreeze({
  SESSION_KEYS: {
    USE_DEBUG_INFOCLIENT_STUB,
    DEBUG_MODE,
    NOAEM_REDIRECT_FF,
    CUSTOMER_DEMANDS_SESSION_KEY,
  },
  LOCAL_KEYS: {
    USE_DEBUG_INFOCLIENT_STUB,
    DEBUG_MODE,
  },
  INFOCLIENT_TTL: 3 * 60 * 1000,
  URLS: {
    INFOCLIENT_STUB_URL: "/rsc/contrib/stubs/infosClient.json",
    INFOCLIENT_URL: "/serviceinfosclient-wspl/rpc/InfosClient",
    BLANK_GIF_URL: "/rsc/contrib/image/icons/small/blank.gif",
  },
  COMPONENTS_SELECTOR: "._componentContainer",
  CLIENT_TYPES: {
    PART: "PART",
    BPF: "BPF",
    PRO: "PRO",
    HB: "HB",
  },
});
