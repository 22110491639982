// TODO: unit tests
export const Deferred = {
  create: function () {
    let d = null;
    let p = new Promise(function (resolve, reject) {
      d = {
        pending: true,
        resolved: false,
        rejected: false,
        resolve: function () {
          const args = Array.from(arguments);
          const promise = Promise.resolve.apply(Promise, args);

          resolve.apply(this, args);

          promise
            .then(
              function () {
                this.pending = false;
                this.resolved = true;
                this.rejected = false;
              }.bind(this)
            )
            .catch(
              function () {
                this.pending = false;
                this.resolved = false;
                this.rejected = true;
              }.bind(this)
            );

          return promise;
        },
        reject: function () {
          const args = Array.from(arguments);
          const promise = Promise.reject.apply(Promise, args);

          reject.apply(this, args);

          promise.catch(
            function () {
              this.pending = false;
              this.resolved = false;
              this.rejected = true;
            }.bind(this)
          );

          return promise;
        },
        isStateResolved: function () {
          return !!this.resolved;
        },
        isStatePending: function () {
          return !!this.pending;
        },
        isStateRejected: function () {
          return !!this.rejected;
        },
      };
    });

    return Object.assign(p, d);
  },
};
