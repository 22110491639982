import $ from "jquery";
import { $AsyncDomReady, $body } from "./jqVariables.mjs";
import { isDebug } from "./environement.mjs";
import moment from "moment";
import * as clientUtils from "../Helpers/functionalHelpers/typeClientUtils.mjs";
import {exportToWindow} from "./exportTool.mjs";
import {logFactory} from "./debug.mjs";

const Log = logFactory("languageManagement.js");
export let LANGUAGE = "fr"; // langue du site : 'fr' ou 'en'
export let LABELS = {};
export const IS_TRANSLATION_ACTIVATED = window.location.protocol !== "file:";

export const languageManagement = (function languageManagement() {
  // Alias
  // get the translation from a key
  const LABELS_PATH = "/rsc/contrib/script/client/labels.json";
  const LOCAL_STORAGE_ITEM_NAME = "labels_cache";
  let getLanguage;

  //--
  function isEnglish() {
    initLanguage();
    return window.LANGUAGE === "en";
  }

  function isLabelEnglish() {
    return LABELS["label.language"] === "en";
  }

  function initLanguage() {
    let _location, isFrench, isEnglish, result;

    _location = document.location;
    isFrench = _location.pathname.indexOf("/fr/") === 0 || $body.hasClass("fr");
    isEnglish =
      _location.pathname.indexOf("/en/") === 0 || $body.hasClass("en");

    result =
      (isDebug() &&
        ["fr", "en"].includes(localStorage["AEM_LANGUE"]) &&
        localStorage["AEM_LANGUE"]) ||
      (isFrench && "fr") ||
      (isEnglish && "en") ||
      "fr";

    moment.locale(result);

    return result;
  }

  LANGUAGE = initLanguage();
  exportToWindow({ LANGUAGE });

  //----
  getLanguage = initLanguage;
  //--
  function init(callBack) {
    // récuperation des labels de langue

    $AsyncDomReady().then(function () {
      let langue;
      let isHomePage;
      let isConnectionPage;
      let isPageForDownloading;

      const _callBack = (function () {
        let result = $.noop;

        if (typeof callBack === "function") {
          result = callBack;
        }

        return result;
      })();

      isHomePage = clientUtils.isHomePage();
      isConnectionPage = clientUtils.isConnectionPage();
      isPageForDownloading = isHomePage || isConnectionPage;
      langue = getLanguage();

      function initLabelsFromServer() {
        $.ajax({
          cache: false,
          url: LABELS_PATH,
          dataType: "json",
        })
          .done(function (data) {
            //--
            if (data && data[langue]) {
              LABELS = data[langue];
              localStorage.setItem(
                LOCAL_STORAGE_ITEM_NAME,
                JSON.stringify(LABELS)
              );
              //--
              _callBack();
            } else {
              LABELS = JSON.parse(
                localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)
              );
            }
          })
          .fail(function () {
            Log.error("Echec de chargement des traductions!");
          });
      }
      //---
      if (isPageForDownloading) {
        initLabelsFromServer();
      } else {
        LABELS = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_NAME));
        if (LABELS) {
          const isPageEnglish = isEnglish();
          const _isLabelEnglish = isLabelEnglish();

          if (isPageEnglish === _isLabelEnglish) {
            _callBack();
          } else {
            initLabelsFromServer();
          }
        } else {
          initLabelsFromServer();
        }
      }
    });
  }
  //---
  function get(key, _default) {
    return LABELS[key] || _default || key;
  }

  //===============================
  if (IS_TRANSLATION_ACTIVATED) {
    init(function () {
      exportToWindow({
        LABELS,
      });
    });
  }
  //===============================

  //----
  return {
    init,
    initLanguage,
    getLanguage,
    get,
    isEnglish,
    isLabelEnglish,
  };
})();
//===========================================
export { languageManagement as lgMgt };
export const _get = languageManagement.get;
