import $ from "jquery";
import { assert } from "./lowLevelUtils/assert.mjs";
import { isDefined } from "./lowLevelUtils/isDefined.mjs";

/**
 * It takes an object as an argument, and exports all of its properties to the global scope
 * @param objArgs - a plain object containing the variables to be exported to the window object.
 */
export function exportToWindow(objArgs) {
  assert(isDefined(objArgs), "Missing argument");
  assert($.isPlainObject(objArgs), "Illegal argument: not a plain object!");

  Object.keys(objArgs).forEach(function (varName) {
    window[varName] = objArgs[varName];
  });
}

/**
 * "Export the object to the window and to the global site."
 *
 * The function is called with an object as an argument. The object has two properties:
 *
 * * `name`: The name of the object to export.
 * * `object`: The object to export
 * @param objArgs - {
 */
export function exportGlobally(objArgs) {
  exportToWindow(objArgs);
  exportToGlobalSite(objArgs);
}

/**
 * It takes an object and assigns it to the window.GlobalSite object
 * @param objToExport - The object you want to export to the global site.
 */
export function exportToGlobalSite(objToExport) {
  Object.assign(window.GlobalSite, objToExport);
}
