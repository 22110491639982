/**
 * @param start
 * @param end
 * @param step
 * @returns {any}
 * example: [...range(1, 3, 1)] => [1, 2, 3]
 */
export function* range(start, end, step = 1) {
    yield start;
    if (start === end || start > end - step) return;
    yield* range(start + step, end, step);
}