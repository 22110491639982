import {safeAccess} from "./safeAccess.mjs";
import {assert} from "./lowLevelUtils/assert.mjs";
import {isDefined} from "./lowLevelUtils/isDefined.mjs";

/**
 * > It checks if a value exists in the global scope, and if it doesn't, it throws an error
 * @param queryObjStr - The string that will be used to access the object in the window object.
 * @returns The value of the object at the end of the queryObjStr.
 */
export function checkDependency(queryObjStr) {
    const valueObj = safeAccess(window, queryObjStr);

    assert(isDefined(valueObj), "Missing " + queryObjStr);

    return valueObj;
}

/**
 * It takes an array of dependencies and returns an array of booleans
 * @param dependencies - An array of strings that represent the dependencies to check.
 * @returns An array of booleans.
 */
export function checkDependencies(...dependencies) {
    return dependencies.flat().map(checkDependency);
}