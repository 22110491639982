import $ from "jquery";
import { get$document } from "../coreUtils/jqVariables.mjs";
import { MediaQueryHelper } from "./mediaQueryHelper.mjs";
import { exportToWindow } from "../coreUtils/exportTool.mjs";
import { logFactory } from "../coreUtils/debug.mjs";

const { lesserThan, greaterThan, on, isMatch } = MediaQueryHelper;

const Log = logFactory("HelperDeviceSize.js");

const mobileMedia = lesserThan("m", { strict: true });
const desktopMedia = greaterThan("m", { strict: false });

function device() {
  return isMatch(mobileMedia) ? "mobile" : "desktop";
}

function triggerBreakPointReached() {
  get$document().trigger("breakPointReached", device());

  Log.log({
    ["breakPointReached"]: device(),
  });
}

const toExport = {
  initialized: false,
  size: window.innerWidth,
  device,
  isDesktop() {
    return this.device() === "desktop";
  },
  isMobile() {
    return this.device() === "mobile";
  },
  init() {
    if (this.initialized) return;
    // legacy
    Log.log("--> init");

    on(mobileMedia, {
      success: triggerBreakPointReached,
    });

    on(desktopMedia, {
      success: triggerBreakPointReached,
    });

    this.initialized = true;
  },
  // Function that returns the type of device interpreted by the CSS stylesheet (media queries)
  getDeviceState() {
    // retained for legacy compatibility
    return this.device();
  },
  getContentState() {
    // legacy
    return this.device();
  },
  resizeDevice() {
    // legacy
    // Do nothing
  },
};

$(function () {
  toExport.init();
});

//===========================================
export const DeviceSize = toExport;
//===========================================
