import { getBreakPoints } from "../Configurations/siteSettings/SiteSettingsHelper.mjs";

import {logFactory} from "../coreUtils/debug.mjs";

const Log = logFactory("mediaQueryHelper.mjs");
const { log } = Log;

/**

 * Converts a CSS value breakpoint to a number and unit.
 *
 * @param {string} value - The CSS value breakpoint to convert.
 * @returns {Object} - An object containing the converted number and unit.
 *
 * @example
 * // Example usage
 * const breakpoint = cssValueBreakPointToNumber("768px");
 * console.log(breakpoint);
 * // Output: { number: 768, unit: "px" }
 */
function cssValueBreakPointToNumber(value) {
  const number = (function () {
    const _number = parseFloat(value ?? "-1");
    return Number.isNaN(_number) ? -1 : _number;
  })();

  const unit = (function () {
    const _unit = value?.replace(number, "")?.trim();
    return number === -1 ? "" : _unit;
  })();

  return {
    number,
    unit,
  };
}

/**

 * Retrieves the value of a specific breakpoint from the siteSettings object.
 * It uses the getBreakPoints function to get the breakpoints object,
 * and then passes the value of the specified breakpoint to the cssValueBreakPointToNumber function to convert it into a number and unit.
 * The function returns the converted value.
 *
 * @param {string} breakpoint - The name of the breakpoint.
 * @returns {object} - The converted value of the specified breakpoint.
 *
 * @example
 * const breakpointValue = getBreakPoint("md");
 * console.log(breakpointValue); // Output: { number: 768, unit: 'px' }
 */
function getBreakPoint(breakpoint) {
  const breakpoints = getBreakPoints();

  return cssValueBreakPointToNumber(
    breakpoints?.[(breakpoint ?? "").toLowerCase()],
  );
}

/**

 * Retrieves the value of a specific breakpoint from the siteSettings object.
 * Uses the getBreakPoint function to get the value of the specified breakpoint,
 * and returns the number of the breakpoint.
 *
 * @param {string} breakpoint - The name of the breakpoint.
 * @returns {number} The number of the breakpoint.
 *
 * @example
 * const breakpointValue = getBreakPointValue("md");
 * console.log(breakpointValue); // Output: 768
 */
function getBreakPointValue(breakpoint) {
  return getBreakPoint(breakpoint)?.number;
}

/**

 * This function takes a breakpoint parameter.
 * It calls the getBreakPoint function to get the number and unit of the breakpoint value.
 * It then combines the number and unit into a string and returns it.
 *
 * @param {string} breakpoint - The breakpoint value.
 * @returns {string} The breakpoint value as a string.
 *
 * @example
 * const breakpointValue = getBreakPointValueString("md");
 * console.log(breakpointValue); // Output: "768px"
 */
function getBreakPointValueString(breakpoint) {
  const num = getBreakPoint(breakpoint)?.number;
  const unit = getBreakPoint(breakpoint)?.unit;

  return `${num}${unit}`;
}

/**

 * Returns a list of breakpoints with their corresponding values.
 *
 * @returns {Array} - An array of objects containing the breakpoint and its value.
 *
 * @example
 * // Usage example
 * const breakpoints = getBreakPointList();
 * console.log(breakpoints);
 * // Output: [{ "sm": 576 }, { "md": 768 }, { "lg": 992 }, { "xl": 1200 }]
 */
function getBreakPointList() {
  return Object.keys(getBreakPoints()).map((breakpoint) => ({
    [breakpoint]: getBreakPoint(breakpoint),
  }));
}

/**

 * This function returns a sorted list of breakpoints based on their numerical values.
 *
 * @return {Array} A sorted list of breakpoints in the format [{ breakpoint1: value1 }, { breakpoint2: value2 }, ...]
 *
 * @example
 * const sortedBreakPoints = getSortedBreakPointList();
 * console.log(sortedBreakPoints);
 * // Output: [{ breakpoint1: value1 }, { breakpoint2: value2 }, ...]
 */
function getSortedBreakPointList() {
  return getBreakPointList()?.sort((a, b) => {
    const aNum = Object.values(a)?.[0]?.number;
    const bNum = Object.values(b)?.[0]?.number;

    return aNum - bNum;
  });
}

/**

 * Returns a list of breakpoint keys.
 * @returns {Array} The list of breakpoint keys.
 *
 * @example
 * const breakpointKeys = getBreakPointKeysList();
 * console.log(breakpointKeys); // ["sm", "md", "lg", "xl"]
 */
function getBreakPointKeysList() {
  return getSortedBreakPointList()?.map((item) => Object.keys(item)?.[0]);
}

/**

 * Returns a string representation of the breakpoint value from an object.
 *
 * @param {Object} obj - The object containing the breakpoint value.
 * @param {number} obj.number - The number value of the breakpoint.
 * @param {string} obj.unit - The unit of the breakpoint value.
 * @returns {string} The string representation of the breakpoint value.
 *
 * @example
 * const breakpoint = { number: 768, unit: 'px' };
 * const breakpointValue = getBreakPointValueStringFromObject(breakpoint);
 * console.log(breakpointValue); // Output: "768px"
 */
function getBreakPointValueStringFromObject({ number, unit }) {
  return `${number}${unit}`;
}

const breakPointKeysList = getBreakPointKeysList();

function lesserThan(breakpointKey, { strict } = { strict: false }) {
  const breakPoint = getBreakPoint(breakpointKey);
  const breakPointValue = (breakPoint?.number ?? 0) - (strict ? 1 : 0);
  const breakPointUnit = breakPoint?.unit;
  const valueString = getBreakPointValueStringFromObject({
    number: breakPointValue,
    unit: breakPointUnit,
  });
  const result = `(max-width: ${valueString})`;

  log({
    ["Debug"]: {
      strict,
      breakpointKey,
      breakPoint,
      breakPointValue,
      breakPointUnit,
      valueString,
      result,
    },
  });

  return result;
}

function greaterThan(breakpointKey, { strict } = { strict: false }) {
  const breakPoint = getBreakPoint(breakpointKey);
  const breakPointValue = (breakPoint?.number ?? 0) + (strict ? 1 : 0);
  const breakPointUnit = breakPoint?.unit;
  const valueString = getBreakPointValueStringFromObject({
    number: breakPointValue,
    unit: breakPointUnit,
  });
  const result = `(min-width: ${valueString})`;

  log({
    ["Debug"]: {
      strict,
      breakpointKey,
      breakPoint,
      breakPointValue,
      breakPointUnit,
      valueString,
      result,
    },
  });

  return result;
}

function between({ min, max }) {
  const minBreakPoint = getBreakPoint(min.key);
  const maxBreakPoint = getBreakPoint(max.key);

  const minValue = (minBreakPoint?.number ?? 0) + (min.strict ? 1 : 0);
  const maxValue = (maxBreakPoint?.number ?? 0) - (max.strict ? 1 : 0);

  const minUnit = minBreakPoint?.unit;
  const maxUnit = maxBreakPoint?.unit;

  const minValueString = getBreakPointValueStringFromObject({
    number: minValue,
    unit: minUnit,
  });

  const maxValueString = getBreakPointValueStringFromObject({
    number: maxValue,
    unit: maxUnit,
  });

  const result = `(min-width: ${minValueString}) and (max-width: ${maxValueString})`;

  log({
    ["Debug"]: {
      min,
      max,
      minBreakPoint,
      maxBreakPoint,
      minValue,
      maxValue,
      minUnit,
      maxUnit,
      minValueString,
      maxValueString,
      result,
    },
  });

  return result;
}

function not(media) {
  return `( not( ${media} ) )`;
}

function or(...mediaList) {
  return mediaList.join(", ");
}

const medias = [];

function on(media, { success = function () {}, failed = function () {} }) {
  const matchMedia = medias[media] ?? window.matchMedia(media);
  medias[media] = matchMedia;

  function eventHandler(event) {
    if (event.matches) {
      success();
    } else {
      failed();
    }
  }
  matchMedia.addEventListener("change", eventHandler);

  // test is done before changes done
  if (medias[media].matches) {
    success();
  } else {
    failed();
  }
}

function isMatch(media) {
  return window.matchMedia(media).matches;
}

export const MediaQueryHelper = {
  getBreakPointValueStringFromObject,
  getBreakPointKeysList,
  getSortedBreakPointList,
  getBreakPointList,
  getBreakPointValueString,
  getBreakPointValue,
  getBreakPoint,
  cssValueBreakPointToNumber,
  lesserThan,
  greaterThan,
  between,
  not,
  or,
  on,
  medias, // debug purpose
  isMatch,
};
