import $ from "jquery";
import { constantes } from "../constantes.mjs";
import { isDefined } from "./lowLevelUtils/index.mjs";

/**
 * It returns true if the element exists, and false if it doesn't
 * @param selector - The selector to check for.
 * @returns The length of the element.
 */
export function isEltExists(selector) {
  return $(selector).length > 0;
}

/**
 * It returns a selector for a component, given its name
 * @param compName - the name of the component
 * @returns A string that is the selector for the component.
 */
export function getSelectorForComp(compName) {
  return constantes.COMPONENTS_SELECTOR + "._" + compName;
}

/**
 * It checks if a component exists(html on th page)
 * @param compName - The name of the component.
 * @param Log - an instance of the Log class.
 * @returns A boolean value.
 */
export function isCompExists(compName, Log) {
  const compSelector = getSelectorForComp(compName),
    res = isEltExists(compSelector);

  if (isDefined(Log) && !res) {
    Log.log(compSelector + " doesn't exist!");
  }

  return res;
}

/**
 * It takes four numbers as arguments and returns a string that can be used as a value for the rootMargin property of the
 * IntersectionObserver constructor
 * @param _top - The top margin of the root element.
 * @param _right - The amount of space to the right of the viewport to observe.
 * @param _bottom - The bottom margin of the root element.
 * @param _left - The left margin of the root element.
 * @returns A string of the top, right, bottom, and left values with "px" appended to each value.
 */
export function rootMargin(_top, _right, _bottom, _left) {
  const top = _top || 0;
  const right = _right || 0;
  const bottom = _bottom || 0;
  const left = _left || 0;

  return [top, right, bottom, left]
    .map(function (item) {
      return item + "px";
    })
    .join(" ");
}
