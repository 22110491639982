/**
 * > The function `assert` takes two arguments, `condition` and `message`, and throws an error if `condition` is false
 * @param condition - The condition to test.
 * @param message - The message to display if the assertion fails.
 * @returns An object with a single property, assert, which is a function.
 */
export function assert(condition, message) {
    if (!condition) {
        throw new Error(message || "Assertion failed");
    }

    return {
        assert,
    };
}