import $ from "jquery";

/**
 *
 * @returns :{Promise}
 */
export function getAsyncDateServer() {
  return $.ajax({
    url: "/",
    cache: false,
  }).then(function (data, textStatus, jqXHR) {
    return jqXHR.getResponseHeader("date");
  });
}
