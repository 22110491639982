export const siteSettings = {
  "mainBodyClasses": [
    "hellobank"
  ],
  "clientLibsSiteFolder": "bnpparibas-neo-hb-acquisition-site-front",
  "contentDamRootFolder": "hellobank-neo-aquisition",
  "clientlibsCategoriesPrefix": "hb-acquisition",
  "scssVars": {
    "breakpoints": {
      "xs": "480px",
      "s": "768px",
      "m": "1024px",
      "l": "1280px",
      "xl": "1440px"
    }
  }
};