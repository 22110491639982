import $ from "jquery";
import { Deferred } from "../Helpers/Deferred.mjs";
import { setIntervalHighPrecision } from "../Helpers/setIntervalHighPrecision.mjs";

//=============================================*
// TODO: tests unitaires

class Chrono {
  constructor() {
    this.date0 = this.date = new Date();
  }
  tick(_callBack) {
    const callBack = typeof _callBack === "function" ? _callBack : () => {};

    this.date = new Date();

    callBack(this.delta());

    return this;
  }

  delta() {
    return this.date.getTime() - this.date0.getTime();
  }
}

export const WaitAsync = (function () {
  // TODO: a convertir en utilisant les classes es6
  const DELAY = 300;
  const toWaitSet = new Set();
  const resolvedSet = new Set();

  function Test(_predicat, _valuator, _maxCycles, _timeOut) {
    const valuator = typeof _valuator === "function" ? _valuator : $.noop;
    const maxCycles = _maxCycles || Infinity;
    const timeOut = _timeOut || Infinity;

    if (typeof _predicat !== "function") {
      throw "predicat must be a function";
    }

    this.chrono = new Chrono();
    this.counter = 0;
    this.deltaTime = this.chrono.delta();
    this._predicat = _predicat;
    this.predicat = function () {
      return (
        this._predicat() ||
        this.counter >= maxCycles ||
        this.deltaTime >= timeOut
      );
    };
    this.valuator = valuator;
    this.resolvedValue = null;
    this.deferred = Deferred.create();
    this.deferred
      .then(
        function (value) {
          this.resolvedValue = value;

          return value;
        }.bind(this),
      )
      .then(
        function (value) {
          removeTest(this);

          return value;
        }.bind(this),
      );
  }

  Test.prototype = {
    exec: function () {
      this.counter++;
      this.chrono.tick(
        function (deltaTime) {
          this.deltaTime = deltaTime;
        }.bind(this),
      );
      if (this.predicat()) {
        this.deferred.resolve(this.valuator());
      }
    },
    getAsyncVal: function () {
      return this.deferred;
    },
  };

  const waitIntervalId = setIntervalHighPrecision(function () {
    Array.from(toWaitSet).map(function (test) {
      setTimeout(function () {
        test.exec();
      }, 10);
    });
  }, DELAY);

  function addTest(test) {
    if (test instanceof Test) {
      toWaitSet.add(test);
    }
  }

  function removeTest(test) {
    toWaitSet.delete(test);
    resolvedSet.add(test);
  }

  const TestFactory = (function () {
    function create(_predicat, _valuator, _maxCycles, _timeOut) {
      return new Test(_predicat, _valuator, _maxCycles, _timeOut);
    }

    function createFromObj({ predicat, valuator, maxCycles, timeOut }) {
      return new Test(predicat, valuator, maxCycles, timeOut);
    }

    return {
      create,
      createFromObj,
    };
  })();

  return {
    addTest,
    removeTest,
    TestFactory,
    inspect: function () {
      return {
        toWaitSet,
        resolvedSet,
        waitIntervalId,
      };
    },
  };
})();

/**
   *
   * @param objArg : {
   *    predicat,
        valuator,
        maxCycles,
        timeOut
   * }
   * @constructor
   */
export const SimpleWaitAsync = function SimpleWaitAsync(objArg) {
  const TestFactory = WaitAsync.TestFactory,
    test = TestFactory.createFromObj(objArg);

  WaitAsync.addTest(test);

  return test.getAsyncVal();
};

/**
 * @legacy
 * @param predicat
 * @returns {jQuery}
 */
export const waitFor = function waitFor(predicat) {
  const TestFactory = WaitAsync.TestFactory,
    test = TestFactory.create(predicat);

  WaitAsync.addTest(test);

  return test.getAsyncVal();
};

export function DetectChange(_valuator, onChange) {
  if (this instanceof DetectChange) {
    this._valuator = _valuator || function () {};
    this._valuator.value = this._valuator();
    this._valuator.oldValue = this._valuator();

    const _predicat = function _predicat() {
      this._valuator.oldValue = this._valuator.value;
      this._valuator.value = this._valuator();

      var value = this._valuator.value;
      var oldValue = this._valuator.oldValue;

      return value !== oldValue;
    }.bind(this);

    const createTestForChange = function () {
      return WaitAsync.TestFactory.createFromObj({
        predicat: _predicat,
        valuator: _valuator,
      });
    };

    function listenForChange(value) {
      const testForChange = createTestForChange();

      listenForChange.count = !listenForChange.count
        ? 0
        : listenForChange.count;

      /*
         NB: à la première execution de listenForChange , le compteur est à zero
         */
      if (listenForChange.count > 0) {
        onChange(value);
      }

      listenForChange.count += 1;

      WaitAsync.addTest(testForChange);

      testForChange
        .getAsyncVal()
        .then(listenForChange)
        .catch(function (err) {
          console.error("Big Boom Badaboom ", err);
        });
    }

    listenForChange();
  } else {
    return new DetectChange(_valuator, onChange);
  }
}

export function setTimeoutAsync(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

Object.assign(WaitAsync, {
  waitFor,
  DetectChange,
  SimpleWaitAsync,
  setTimeoutAsync
});
