import $ from "jquery";
import { _get } from "../../coreUtils/languageManagement.mjs";
import {
  addThousandsSep,
  formatNumber,
  replaceComa,
  replaceDot,
} from "../../plumbing/circularReferencesSolver.mjs";
import { trace } from "../../coreUtils/environement.mjs";

const jQuery = $;

// ======= : warnings from es6 modularization
const _fieldsToStoreInSession = window._fieldsToStoreInSession; // initialization by other scripts or modules
const _calculationGroups = window._calculationGroups; // // initialization by other scripts or modules
// ========

export const FormHelper = {
  currentSessionStorage: {},

  showResetButton($current_element) {
    if ($current_element.siblings(".reset-input").length === 0) {
      $('<span class="reset-input" />').insertAfter($current_element);
    } else {
      $current_element.siblings(".reset-input").removeClass("hidden");
    }
  },
  hideResetButton($current_element) {
    $current_element.siblings(".reset-input").addClass("hidden");
  },
  resetInput($elem) {
    $elem.on("click.form-reset-field", ".reset-input", function () {
      $(this).addClass("hidden").siblings(".form-control").val("").focus();
    });
  },
  textareaCompteur() {
    // Pour IE, on enlève ce qui dépasse car maxlength n'est pas supporté sur les textareas dans IE
    $("textarea[maxlength]").on("keyup blur", function () {
      // Store the maxlength and value of the field.
      const maxlength = $(this).attr("maxlength");
      const val = $(this).val();

      // Trim the field if it has content over the maxlength.
      if (val.length > maxlength) {
        $(this).val(val.slice(0, maxlength));
      }
    });

    function autoGrow(oField) {
      if (oField.tagName === "TEXTAREA") {
        if (oField.scrollHeight > oField.clientHeight) {
          oField.style.height = oField.scrollHeight + "px";
        }
      }
    }

    const $field = $(".js-caractere-restant")
      .parents("form")
      .find("textarea,.js-input-compteur");

    $field.each(function () {
      autoGrow($(this)[0]);
      const max_char = $(this).attr("maxlength"),
        $current_field = $(this);

      // If you don't have a counter you stay
      if (
        $current_field.parents("form").find(".js-caractere-restant").length ===
        0
      ) {
        return;
      }

      $current_field.on("keyup", function () {
        autoGrow($(this)[0]);
        setTimeout(function () {
          let str = _get("next.formhelper.charact1", "caractères restants"),
            current_text = $current_field.val(),
            remaining = max_char - current_text.length;

          if (remaining <= 1) {
            str = _get("next.formhelper.charact2", "caractère restant");
          }
          $current_field
            .parents("form")
            .find(".js-caractere-restant")
            .html("<span>" + remaining + "</span> " + str);
        }, 10);
      });
    });
  },
  resetButton() {
    if ($("form").length === 0) {
      return;
    }
    $('button[type="reset"]').click(function () {
      $(this)
        .parents("form")
        .find(".valid")
        .each(function () {
          $(this).removeClass("valid").parent().removeClass("fixFFSelect");
        });
    });
  },
  autofill() {
    //L'autofill n'a lieu que si le name du form existe dans le fichier de config rsc\contrib\script\client\simulation-credit.js
    $("form").each(function () {
      let $currentForm = $(this);
      let formName = $(this).attr("name");
      let ElToBeClicked = [];

      if (typeof _fieldsToStoreInSession == "undefined") {
        return;
      }

      if (typeof _fieldsToStoreInSession[formName] != "undefined") {
        //On essaie de remplir les éléments du formulaire à partir des données en sessionStorage
        if (sessionStorage.getItem(formName) != null) {
          let formDatas = JSON.parse(
            sessionStorage.getItem($currentForm.attr("name"))
          );
          $currentForm.find("input").each(function () {
            if ($(this).attr("type") === "text" && $(this).val() !== "") {
              return;
            } //Au cas où un champs serait pré-rempli en dur

            if ($(this).attr("type") === "checkbox") {
              if (formDatas[$(this).attr("name")]) {
                $(this).prop("checked", "checked");
              } else {
                $(this).removeAttr("checked");
              }
            } else if (
              $(this).attr("type") === "hidden" ||
              $(this).hasClass("typeHidden")
            ) {
              //Ce cas est utilisé notamment dans le cadre des "data-send-value"
              if (formDatas[$(this).attr("name")]) {
                $(this).val(formDatas[$(this).attr("name")]);

                let currentValue = formDatas[$(this).attr("name")];
                //Cas des data-send-value :
                //Pour que l'élément qui envoie la valeur à l'input (data-send-value) ait sa classe "active" ou autre selon les pages
                //Il faut qu'il soit clické.
                //Or si on clique maintenant, on enregistrera une donnée en sessionStorage alors qu'on n'a pas fini l'autofill
                //et donc on perdra des données.
                //
                //=> On va plutôt stocker l'élément dans l'array (ElToBeClicked) qui liste les éléments qui doivent recevoir un clic en fin d'autofill
                //
                ElToBeClicked.push(
                  $(
                    `[name="${$currentForm.attr("name")}"] [name="${
                      formDatas[$(this).attr("name")]
                    }"]`
                  )
                );

                //Cas des custom dropDown :
                //On simule un clic sur le li adéquat dans le dropdown
                $(this)
                  .parents(".dropdown-container")
                  .find(".hidden-value")
                  .each(function () {
                    if ($.trim($(this).html()) == currentValue) {
                      ElToBeClicked.push($(this).parents("li").first());
                    }
                  });
              }
            } else if ($(this).attr("type") === "radio") {
              if (formDatas[$(this).attr("name")]) {
                $(this).prop("checked", false);
                if ($(this).val() == formDatas[$(this).attr("name")]) {
                  $(this).prop("checked", true);
                  let label = $(`label[for="${$(this).attr("id")}"]`);
                  if (
                    label.is("[data-toggle-section]") ||
                    label.is("[data-toggle-radio-section]") ||
                    label.is("[data-show-section]") ||
                    label.is("[data-hide-section]") ||
                    label.is("[data-make-active]") ||
                    label.is("[data-make-inactive]") ||
                    label.is("[data-send-value]")
                  ) {
                    if (label.length > 0) {
                      label.click();
                    }
                  }
                }
              }
            } else {
              $(this).val(formDatas[$(this).attr("name")]);
              FormHelper.removeGroupValidation($(this), true);
            }

            if (formDatas[$(this).attr("name")]) {
              FormHelper.currentSessionStorage[$(this).attr("name")] =
                formDatas[$(this).attr("name")];
            }
          });

          $currentForm.find("textarea").each(function () {
            if (formDatas[$(this).attr("name")]) {
              $(this).text(formDatas[$(this).attr("name")]);
            }
          });

          $currentForm.find("select").each(function () {
            if (formDatas[$(this).attr("name")]) {
              $(this)
                .find("option")
                .each(function () {
                  $(this).removeAttr("selected");
                });

              $(this)
                .find(`[value="${formDatas[$(this).attr("name")]}"]`)
                .prop("selected", true);
              FormHelper.currentSessionStorage[$(this).attr("name")] =
                formDatas[$(this).attr("name")];
            }
          });

          //The autofill is finished, you can click on the elements that need to be clicked
          for (let i = 0; i < ElToBeClicked.length; i++) {
            ElToBeClicked[i].click();
          }
        }
      }
    });
  },
  /*
   * Fonction qui effectue des calculs entre plusieurs champs du formulaire
   * Les champs impliqués sont définis dans rsc\contrib\script\client\simulation-credit.js
   *  nbrOfDigits   Le nombre de chiffres après la virgule
   */
  makeCalculations(nbrOfDigits) {
    if (typeof nbrOfDigits == "undefined") {
      nbrOfDigits = 0;
    }
    if (typeof _calculationGroups != "undefined") {
      for (let p in _calculationGroups) {
        if (_calculationGroups.hasOwnProperty(p)) {
          _calculationGroups[p].valToDisplay = 0;
          let currentName = _calculationGroups[p].name;
          let currentGroup = $(`[name="${currentName}"]`);

          let elToInclude =
            _calculationGroups[p]?.addItems?.concat(
              _calculationGroups[p]?.subItems
            ) ?? [];

          if (currentGroup.length > 0) {
            for (let i = 0; i < elToInclude.length; i++) {
              currentGroup
                .find(`[name="${elToInclude[i]}"]`)
                .attr("group-num", p)
                .change(function () {
                  let groupNum = $(this).attr("group-num");
                  currentGroup = $(
                    `[name="${_calculationGroups[groupNum].name}"]`
                  );

                  _calculationGroups[groupNum].valToDisplay = 0;
                  for (
                    let l = 0;
                    l < _calculationGroups[groupNum]?.addItems?.length;
                    l++
                  ) {
                    _calculationGroups[groupNum].valToDisplay =
                      Number(_calculationGroups[groupNum].valToDisplay) +
                      Number(
                        $.trim(
                          Number(
                            replaceComa(
                              currentGroup
                                .find(
                                  `input[name="${_calculationGroups[groupNum]?.addItems?.[l]}"]`
                                )
                                .val()
                            )
                          )
                        )
                      );
                  }

                  for (
                    let m = 0;
                    m < _calculationGroups[groupNum]?.subItems?.length;
                    m++
                  ) {
                    _calculationGroups[groupNum].valToDisplay =
                      Number(_calculationGroups[groupNum].valToDisplay) -
                      Number(
                        $.trim(
                          Number(
                            replaceComa(
                              currentGroup
                                .find(
                                  `input[name="${_calculationGroups[groupNum]?.subItems?.[m]}"]`
                                )
                                .val()
                            )
                          )
                        )
                      );
                  }

                  if (_calculationGroups[groupNum].valToDisplay < 0) {
                    _calculationGroups[groupNum].valToDisplay = 0;
                  }

                  if (!isNaN(_calculationGroups[groupNum].valToDisplay)) {
                    for (
                      let j = 0;
                      j < _calculationGroups[groupNum].result.length;
                      j++
                    ) {
                      currentGroup
                        .find(
                          `[name="${_calculationGroups[groupNum].result[j]}"]`
                        )
                        .html(
                          addThousandsSep(
                            formatNumber(
                              _calculationGroups[groupNum].valToDisplay,
                              nbrOfDigits
                            )
                          )
                        );
                    }
                  }
                });
            }

            //Pour que le résultat de l'addition soit déjà visible après pré-remplissage éventuel
            //On effectue le calcul une première fois sans change
            for (let n = 0; n < _calculationGroups[p]?.addItems?.length; n++) {
              _calculationGroups[p].valToDisplay =
                Number(_calculationGroups[p].valToDisplay) +
                Number(
                  $.trim(
                    Number(
                      replaceComa(
                        currentGroup
                          .find(
                            `input[name="${_calculationGroups[p]?.addItems?.[n]}"]`
                          )
                          .val()
                      )
                    )
                  )
                );
            }

            for (let o = 0; o < _calculationGroups[p]?.subItems?.length; o++) {
              _calculationGroups[p].valToDisplay =
                Number(_calculationGroups[p].valToDisplay) -
                Number(
                  $.trim(
                    Number(
                      replaceComa(
                        currentGroup
                          .find(
                            `input[name="${_calculationGroups[p]?.subItems?.[o]}"]`
                          )
                          .val()
                      )
                    )
                  )
                );
            }

            if (_calculationGroups[p].valToDisplay < 0) {
              _calculationGroups[p].valToDisplay = 0;
            }

            if (!isNaN(_calculationGroups[p].valToDisplay)) {
              for (let k = 0; k < _calculationGroups[p].result.length; k++) {
                currentGroup
                  .find(`[name="${_calculationGroups[p].result[k]}"]`)
                  .html(
                    addThousandsSep(
                      formatNumber(
                        _calculationGroups[p].valToDisplay,
                        nbrOfDigits
                      )
                    )
                  );
              }
            }
          }
        }
      }
    }
  },
  cancelCalculations() {
    if (typeof _calculationGroups != "undefined") {
      for (let p in _calculationGroups) {
        if (_calculationGroups.hasOwnProperty(p)) {
          _calculationGroups[p].valToDisplay = 0;
          let currentName = _calculationGroups[p].name;
          let currentGroup = $(`[name="${currentName}"]`);
          if (currentGroup.length > 0) {
            for (let i = 0; i < _calculationGroups[p]?.addItems?.length; i++) {
              currentGroup
                .find(`[name="${_calculationGroups[p]?.addItems?.[i]}"]`)
                .attr("group-num", p)
                .unbind();
            }
          }
        }
      }
    }
  },
  formValidation() {
    if (typeof $.validator == "undefined") {
      trace("validator undefined");
      return;
    }
    jQuery.extend(jQuery.validator.messages, {
      required: "Veuillez renseigner ce champ.",
      remote: "Veuillez remplir ce champ pour continuer.",
      email: "Veuillez entrer une adresse email valide.",
      url: "Veuillez entrer une URL valide.",
      date: "Veuillez entrer une date valide.",
      dateISO: "Veuillez entrer une date valide (ISO).",
      number: "Veuillez entrer un nombre valide.",
      digits: "Veuillez entrer une valeur num&eacute;rique.",
      creditcard: "Veuillez entrer un numéro de carte de crédit valide.",
      equalTo: "Veuillez entrer une nouvelle fois la même valeur.",
      accept: "Veuillez entrer une valeur avec une extension valide.",
      maxlength: jQuery.validator.format(
        "Veuillez ne pas entrer plus de {0} caractères."
      ),
      minlength: jQuery.validator.format(
        "Veuillez entrer au moins {0} caractères."
      ),
      rangelength: jQuery.validator.format(
        "Veuillez entrer entre {0} et {1} caractères."
      ),
      range: jQuery.validator.format(
        "Veuillez entrer une valeur entre {0} et {1}."
      ),
      max: jQuery.validator.format(
        "Veuillez entrer une valeur inférieure ou égale à {0}."
      ),
      min: jQuery.validator.format(
        "Veuillez entrer une valeur supérieure ou égale à {0}."
      ),
      portable: "Veuillez renseigner un numéro de téléphone mobile",
      "portable-msg":
        "Veuillez renseigner votre numéro de téléphone à 10 chiffres commençant par 06 ou 07",
      telephone: "Veuillez renseigner un numéro de téléphone valide",
      "tel-fixe": "Veuillez renseigner un numéro de téléphone valide",
      "tel-fixe-msg":
        "Veuillez entrer un numéro fixe valide à 10 chiffres qui ne commence pas par 06 ou 07",
      postal: "Veuillez entrer un code postal valide",
      "complexe-password":
        "Le format du mot de passe doit être de 6 à 25 caractères et ne peut pas avoir plus de 3 caractères identiques consécutifs",
      notequal: "Cette date a déjà été sélectionnée. Merci de la modifier.",
      alphanum:
        "Seuls les caractères alphanumériques sont autorisés (lettres, chiffres, aucun accent ni caractère de ponctuation)",
      pourcentage: "La valeur entrée ne peut dépasser 100%",
      entier: "Veuillez entrer un nombre sans virgule et supérieur à 0",
      positive: "Veuillez entrer une valeur supérieure à zéro",
      "js-percent-des-input":
        "Le total de répartition de votre investissement ne peut pas dépasser 100 %",
      "multiple-500": "Veuillez entrer un multiple de 500",
      "js-date-obseque": "Veuillez renseigner une date de naissance valide",
      "annees-assur":
        "Vous ne pouvez pas souscrire dans le cadre de la loi Consommation si cela ne concerne pas un logement actuel déjà assuré depuis au moins 1 an et dont l’échéance principale a déjà été renouvelée 1 fois",
      bnpphamon:
        "Si vous êtes déjà client BNP Paribas / Hello bank!, vous ne pouvez pas souscrire dans le cadre de la loi Consommation qui nécessite un changement d’assureur",
      nonehamon:
        "Dans le cadre de la loi Consommation (Loi Hamon), le nom exact de votre compagnie est nécessaire",
      oneCheckbox: "Veuillez choisir au moins une option",
    });

    if ($(".validate-form").length == 0) {
      return;
    }

    const form = $(".validate-form"),
      $field_montant = $(".js-montant-field");

    $field_montant.on("keypress", function (e) {
      const charCode = e.which ? e.which : e.keyCode;

      return !(
        charCode > 31 &&
        charCode != 44 &&
        charCode != 46 &&
        (charCode < 48 || charCode > 57)
      );
    });
    $field_montant.on("paste", function () {
      const $elem = $(this);
      // Settimeout pour récupérer la valeur du champ après avoir collé le texte
      setTimeout(function () {
        const regex = /^(\d|\.|\,)+?$/;
        if (!regex.test($elem.val())) {
          $elem.val("");
        }
      }, 10);
    });
    $.validator.addMethod("multiple-500", function (value, element) {
      return value % 500 == 0;
    });

    $.validator.addMethod("annees-assur", function (value, element) {
      return value != "0";
    });

    $.validator.addMethod("bnpphamon", function (value, element) {
      return !(
        $("[name=motif-resiliation]").val() == "4" &&
        $("[name=assureur-actuel]").val() == "7"
      );
    });
    $.validator.addMethod("nonehamon", function (value, element) {
      return !(
        $("[name=motif-resiliation]").val() == "4" &&
        $("[name=assureur-actuel]").val() == ""
      );
    });

    $("[name=assureur-actuel]").on("change", function () {
      $("[name=motif-resiliation]").change();
    });

    $.validator.addMethod("js-percent-des-input", function (value, element) {
      if (value == 0) {
        return true;
      }

      // Validation des champs pourcentage
      let totalPourc = 0,
        pourcInputs = $(element)
          .parents(".percent-group-validation")
          .find(".js-percent-des-input");

      pourcInputs.each(function () {
        totalPourc = Number(totalPourc) + Number($(this).val());
      });

      return totalPourc <= 100;
    });

    $.validator.addMethod("positive", function (value, element) {
      if (isNaN(value)) {
        return true;
      }
      return this.optional(element) || value > 0;
    });

    $.validator.addMethod("number", function (value, element) {
      const dot = replaceDot(value);

      $(element).val(dot);
      return this.optional(element) || /^\d+,?\d*$/.test(dot);
    });

    // Add custom validation
    $.validator.addMethod("portable", function (value, element) {
      const pattern = /^0(6|7)[0-9]{8}$/gi;
      return this.optional(element) || pattern.test(value);
    });
    $.validator.addMethod("portable-msg", function (value, element) {
      const pattern = /^0(6|7)[0-9]{8}$/gi;
      return this.optional(element) || pattern.test(value);
    });

    // Add custom validation
    $.validator.addMethod("oneCheckbox", function (value, element) {
      const siblings = $(
        "input[data-name=" + $(element).attr("data-name") + "]"
      );
      let length = 0;
      siblings.each(function () {
        if ($(this)[0].checked) length++;
      });

      console.log(length);

      return length > 0;
    });

    // Add custom validation
    $.validator.addMethod("telephone", function (value, element) {
      const pattern = /^0[1-9][0-9]{8}$/gi;
      return this.optional(element) || pattern.test(value);
    });

    $.validator.addMethod("entier", function (value, element) {
      return this.optional(element) || value.indexOf(",") == -1;
    });

    $.validator.addMethod("pourcentage", function (value, element) {
      value = value.replace(",", ".");

      const pattern = /^\d+$/;
      if (!pattern.test(value)) {
        return true;
      }

      return this.optional(element) || value <= 100;
    });

    // Add custom validation
    $.validator.addMethod("tel-fixe", function (value, element) {
      const pattern = /^0(1|2|3|4|5|9)[0-9]{8}$/gi;
      return this.optional(element) || pattern.test(value);
    });

    // Add custom validation
    $.validator.addMethod("tel-fixe-msg", function (value, element) {
      const pattern = /^0(1|2|3|4|5|9)[0-9]{8}$/gi;
      return this.optional(element) || pattern.test(value);
    });

    // Add custom validation
    $.validator.addMethod("postal", function (value, element) {
      const pattern = /^[0-9]{5}$/gi;
      return this.optional(element) || pattern.test(value);
    });

    $.validator.addMethod("date", function (value, element) {
      let date;
      const re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
      let check = false;

      if (value == "") {
        return true;
      }
      // console.log(re.test(value));
      if (re.test(value)) {
        const split = value.split("/");
        const dd = parseInt(split[0], 10);
        const mm = parseInt(split[1], 10);
        const yyyy = parseInt(split[2], 10);
        date = new Date(yyyy, mm - 1, dd);

        if (
          date.getFullYear() == yyyy &&
          date.getMonth() == mm - 1 &&
          date.getDate() == dd
        ) {
          check = true;
        } else {
          return false;
        }
      }

      //la date affichée est elle conforme (elle a peut-être été entrée par l'utilisateur)
      if ($(element).hasClass("js-mindate-today")) {
        //La date ne peut pas être dans le passé
        const today = new Date();
        const startOfToday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        return date.getTime() > startOfToday.getTime() - 1;
      } else if ($(element).hasClass("js-maxdate-today")) {
        //La date ne peut pas être dans le futur
        const today = new Date();
        const demain = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1
        );

        return demain.getTime() > date.getTime();
      } else if ($(element).hasClass("js-maxdate-60")) {
        const today = new Date();
        const dans60jours = new Date(today.getTime() + 60 * 24 * 3600 * 1000);

        return date.getTime() < dans60jours.getTime();
      }

      return check;
    });
    $.validator.addMethod("complexe-password", function (value, element) {
      const pattern = /^(?!(.)\1\1\1).{6,26}$/;
      return this.optional(element) || pattern.test(value);
    });

    $.validator.addMethod("notequal", function (value, element) {
      if (
        typeof $(element).attr("data-notequal") == "undefined" ||
        $(element).attr("data-notequal") == false
      ) {
        return this.optional(element) || true;
      }

      let cpt = 0;
      $(`[data-notequal="${$(element).attr("data-notequal")}"]`).each(
        function () {
          if ($(this).val() == value) {
            cpt++;
          }
        }
      );

      return this.optional(element) || cpt <= 1;
    });

    $.validator.addMethod("alphanum", function (value, element) {
      const pattern = /^[a-z0-9]+$/i;

      return this.optional(element) || pattern.test(value);
    });

    $(".validate-form").each(function () {
      $(this).validate(FormHelper.form_validator_config);
      FormHelper.resetInput($(this));
    });
    $(".validate-form select").on("change", function () {
      $(this).valid();

      //Correction bug épaisseur trait vert sur listes déroulantes sous Firefox
      if (navigator.userAgent.indexOf("Firefox") != -1) {
        $("form select").parent().removeClass("fixFFSelect");
        $("form select.valid, form select.error")
          .parent()
          .addClass("fixFFSelect");
      }
    });

    $("[data-validation-error-msg]").each(function (index) {
      const error_msg = $(this).data("validation-error-msg");
      $(this).rules("add", {
        messages: {
          required: error_msg,
          digits: error_msg,
        },
      });
    });

    (function () {
      const $form_custom_dropdown = $("[id^=select-compte-debiter]");
      if ($form_custom_dropdown.length == 0) {
        return;
      }

      $form_custom_dropdown.on("select-custom-dropdown", function () {
        $(this).removeClass("error").addClass("valid").next(".error").remove();
      });

      $(".validate-form").on("submit", function (e) {
        let error = false;
        $form_custom_dropdown.each(function () {
          if (!$(this).hasClass("valid") && $(this).is(":visible")) {
            $(this).addClass("error");
            $(this)
              .after('<em class="error">Veuillez sélectioner un compte.</em>')
              .show();

            error = true;

            return false;
          }
        });
        if (error) {
          return false;
        }
      });
    })();
  },

  form_validator_config: {
    errorElement: "em",
    errorPlacement(error, element) {
      let code;
      if ($(element).hasClass("iban")) {
        return;
      } // Pas de message d'erreur pour iban

      //L'idée ici est de déclancher un évènement sur l'élément
      // qui est validé pour indiquer qu'il n'est pas valide,
      // et en même temps passer en paramètre le nom de l'erreur
      // (Exemple d'utilisation dans dynacaap titre1)
      if ($(element).hasClass("custom-msg")) {
        let errorName = "";
        for (let prop in jQuery.validator.messages) {
          if (jQuery.validator.messages[prop] == error[0].textContent) {
            errorName = prop;
          }
        }

        element.trigger("not-valid", [errorName, error]);
        return;
      }

      if (
        element.get(0).tagName.toLowerCase() == "input" &&
        $(element).attr("type").toLowerCase() == "checkbox"
      ) {
        if ($(element).parents(".js-carrousel-cards-built").length > 0) {
          //Input faisant partie du carrousel
          const _carrousel = $(element).parents(".js-carrousel-cards-built");
          if (_carrousel.find('input[type="checkbox"]:checked').length == 0) {
            _carrousel.after(
              '<em class="error">Veuillez sélectionner au moins une carte</em>'
            );
            return;
          }
          return;
        }

        const checkboxInputs = $(
          `input[type="checkbox"][name="${$(element).attr("name")}"]`
        );

        if (checkboxInputs.length > 1) {
          if (checkboxInputs.first().data("validation-error-msg")) {
            code =
              '<em class="error">' +
              checkboxInputs.first().data("validation-error-msg") +
              "</em>";
          } else {
            code =
              '<em class="error">Veuillez sélectionner au moins une proposition</em>';
          }
          let checkboxParents = checkboxInputs.first().parents();
          for (let i = 0; i < checkboxInputs.length; i++) {
            checkboxParents = checkboxParents.has(checkboxInputs[i]);
          }
          checkboxParents.first().after(code);
          return;
        }
      }

      if (
        element.get(0).tagName.toLowerCase() == "input" &&
        $(element).attr("type").toLowerCase() == "radio"
      ) {
        if ($(element).parents(".js-carrousel-cards-built").length > 0) {
          //Radio faisant partie d'un carrousel => étoile favoris à ne pas valider
          return;
        }

        const radioInputs = $(
          `input[type="radio"][name="${$(element).attr("name")}"]`
        );
        let radioParents = radioInputs.first().parents();
        for (let i = 0; i < radioInputs.length; i++) {
          radioParents = radioParents.has(radioInputs[i]);
        }

        code = '<em class="error">Veuillez sélectionner une proposition</em>';
        if (radioInputs.filter("[data-validation-error-msg]").length > 0) {
          code =
            '<em class="error">' +
            radioInputs
              .filter("[data-validation-error-msg]")
              .first()
              .data("validation-error-msg") +
            "</em>";
        }
        if (radioParents?.get(0)?.tagName?.toLowerCase?.() == "tbody") {
          radioParents.parents("table").after(code);
          return;
        }
        radioParents.first().after(code);
        return;
      }
      if ($(element).next("button").length > 0) {
        $(element).parent().after(error);
        return;
      }
      if ($(element).parent(".inline-label").length > 0) {
        error.insertAfter($(element).parent(".inline-label"));
        return;
      }

      if ($(element).parent(".checkbox2").length > 0) {
        error.insertAfter($(element).parent(".checkbox2"));
        return;
      }

      // Error placement resizeBarre
      if ($(element).hasClass("range-barre")) {
        error.insertAfter($(element).closest(".curseur-2"));
        return;
      }
      // Error placement oneCheckbox
      if ($(element).hasClass("oneCheckbox")) {
        const container = $(element).closest(".oneCheckbox-wrapper");
        const customError = $(element).attr("data-validation-error-msg")
          ? $(element).attr("data-validation-error-msg")
          : false;
        if (customError) error.text(customError);
        if (container.find("em.error").length < 1) container.append(error);
        return;
      }

      if ($(element).hasClass("flag-autocomplete")) {
        $(element).parent(".eac-flags").append(error);
        return;
      }

      error.insertAfter(element);

      //Correction bug épaisseur trait vert sur listes déroulantes sous Firefox
      if (navigator.userAgent.indexOf("Firefox") != -1) {
        $("form select").parent().removeClass("fixFFSelect");
        $("form select.valid, form select.error")
          .parent()
          .addClass("fixFFSelect");
      }
    },

    ignore: ":hidden:not(.validate-item), .js-init-datepicker:not([required])",

    onfocusout(element, event) {
      const $current_element = $(element);
      if ($current_element.hasClass("js-disable-auto-validation")) {
        return;
      }
      if (
        $current_element.val().trim() == "" &&
        !$current_element.prop("required")
      ) {
        return;
      }
      // On ne valide pas les champs datepicker car au blur le champ va être en erreur,
      // on le valide au onclose du datepicker
      if ($current_element.hasClass("hasDatepicker")) {
        return;
      }

      // Valid current field on focus
      if ($current_element.valid()) {
        $current_element.trigger("is-valid");
        FormHelper.storeDatasInSession($current_element);
        FormHelper.hideResetButton($current_element);
        if (
          typeof $current_element.attr("data-group-validation") !=
            "undefined" &&
          $current_element.val() != ""
        ) {
          FormHelper.checkGroupValidation($current_element, false);
        }
      } else {
        // $current_element.trigger('not-valid');
        if (
          typeof $current_element.attr("data-group-validation") != "undefined"
        ) {
          FormHelper.checkGroupValidation($current_element, true);
        }

        // Affiche le bouton reset seulement si le champ est rempli
        if ($current_element.val() != "") {
          FormHelper.showResetButton($current_element);
        } else {
          FormHelper.hideResetButton($current_element);
        }
      }
    },
    onclick(element, event) {
      //Only used for checkboxes and radio
      const $current_element = $(element);
      if ($current_element.hasClass("js-disable-auto-validation")) {
        return;
      }
      FormHelper.storeDatasInSession($current_element);
    },
    onkeyup(element, event) {
      const $current_element = $(element);

      if ($current_element.hasClass("js-disable-auto-validation")) {
        return;
      }
      if ($current_element.hasClass("error")) {
        // Valide le champ seulement si il est en erreur
        if ($current_element.valid()) {
          $current_element.trigger("is-valid");
          FormHelper.hideResetButton($current_element);
        }
      }
    },
    invalidHandler(event, validator) {
      const errors = validator.numberOfInvalids();
      if (errors) {
        $(".form-error-messages").removeClass("hidden");
      } else {
        $(".form-error-messages").addClass("hidden");
      }
    },
    submitHandler(form) {
      if ($(form).find(".percent-group-validation").hasClass("error")) {
        $(form)
          .find(".percent-group-validation")
          .find(".valid")
          .removeClass("valid");
        return false;
      }

      if (
        $("#total-montant-erreur").length > 0 &&
        !$("#total-montant-erreur").hasClass("hidden")
      ) {
        return false;
      }
      if ($(form).hasClass("custom-submit")) {
        $(form).trigger("submitting");
        return false;
      }
      form.submit();
    },
    rules: {
      duree_financement: {
        min: 2,
        max: 30,
      },
    },
    messages: {
      duree_financement: {
        min: "La valeur de la durée de financement doit être comprise entre 2 et 30",
      },
      codeSMS: {
        required: "Veuillez saisir le code reçu par SMS",
      },
    },
  },
  checkGroupValidation(el, removeEl) {
    const _group = el.attr("data-group-validation");
    $(`[data-group-validation="${_group}"]`).each(function () {
      if (removeEl == false) {
        $(this).attr("required", false);
      } else {
        $(this).attr("required", true);
      }
    });
    if (removeEl == false) {
      el.attr("required", true);
    }
  },
  removeGroupValidation(el) {
    const _group = el.attr("data-group-validation");
    $(`[data-group-validation="${_group}"]`).each(function () {
      $(this).attr("required", false);
    });
  },
  storeDatasInSession(el) {
    //On enregistre les données de l'élément seulement s'il apparaît dans le fichier de config rsc\contrib\script\client
    if (typeof _fieldsToStoreInSession == "undefined") {
      return;
    }
    let formName = el.parents("form").first().attr("name");
    let elName = el.attr("name");
    const v = $.inArray(elName, _fieldsToStoreInSession[formName]);
    if (v != -1) {
      FormHelper.currentSessionStorage[elName] = el.val();
      sessionStorage.setItem(
        formName,
        JSON.stringify(FormHelper.currentSessionStorage)
      );
    }
  },
  emptyDatasInSession() {
    sessionStorage.clear();
  },
  placeholderPolyfill() {
    $("[placeholder]")
      .focus(function () {
        const input = $(this);
        if (input.val() == input.attr("placeholder")) {
          input.val("");
          input.removeClass("placeholder");
        }
      })
      .blur(function () {
        _blur($(this));
      });

    function _blur(input) {
      if (input.val() == "" || input.val() == input.attr("placeholder")) {
        input.addClass("placeholder");
        input.val(input.attr("placeholder"));
      }
    }

    $("[placeholder]").each(function () {
      _blur($(this));
    });
  },
};
