import $ from "jquery";

import {once} from "./once.mjs";

export function $AsyncDomReady() {
  const deferred = $.Deferred();

  $(deferred.resolve);

  return deferred;
}

export const get$body = once(function _get$body() {
  return $("body");
});

export const get$head = once(function _get$head() {
  return $("head");
});

export const get$html = once(function _get$html() {
  return $("html");
});

export const get$document = once(function _get$document() {
  return $(document);
});
export const get$window = once(function _get$window() {
  return $(window);
});

export let $body = $(null);
export let $document = $(null);
export let $html = $(null);
export let $window = $(null);

export let $head = $(null);

$(function () {
  $body = get$body();
  $document = get$document();
  $html = get$html();
  $head = get$head();
  $window = get$window();
});
