import $ from "jquery";
import { WaitAsync } from "./WaitAsync.mjs";
import {
  endJsLoader,
  initJsLoader,
  startJsLoader,
} from "../Helpers/UIHelpers/JsLoader.mjs";
import * as clientUtils from "../Helpers/functionalHelpers/typeClientUtils.mjs";
import { $AsyncDomReady, $body } from "./jqVariables.mjs";
import { logFactory } from "./debug.mjs";

export let insertionIA;

try {
  const compName = "insertion_ia";
  const Log = logFactory(`${compName}.js`);
  const DEFAULT_LOADER_COLOR = "#1da35f";
  const BPF_LOADER_COLOR = "#b2965d";
  const HB_LOADER_COLOR = "#05a5c0";

  /**
   * insertionIA definition
   * @namespace { Object } insertionIA
   * @property { Method } init
   * @property { Method } initDelayed
   * @property { Method } show
   * @property { Method } hide
   */
  insertionIA = (function () {
    let element;

    /**
     * set loader HTMLElement
     * @param {String} color client type brand color value
     */
    function setElement(color) {
      const containerCss = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        "z-index": 1000,
        background: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      };

      const canvasCss = {
        width: "400px",
        height: "400px",
      };

      const loaderParent = $("<div/>")
        .css(containerCss)
        .addClass("loader-container hidden");

      const loaderCanvas = $("<div/>")
        .css(canvasCss)
        .addClass("js-canvas-loader")
        .attr("data-js-canvas-loader-color", color)
        .attr("data-js-canvas-loader-dotnumber", 10)
        .attr("data-js-canvas-loader-dotwidth", 8)
        .attr("data-js-canvas-loader-speed", 1)
        .attr("data-js-canvas-loader-ease", "easeInOutQuint")
        .attr("data-js-canvas-loader-trailmode", "followGlobalEase");

      loaderParent.append(loaderCanvas);

      return loaderParent;
    }

    /**
     * set client type brand color
     */
    function setColor() {
      const isPart = clientUtils.isPart;
      const isBpf = clientUtils.isBpf;
      const isPro = clientUtils.isPro;
      const isHb = clientUtils.isHb;

      if (isPart() || isPro()) {
        return DEFAULT_LOADER_COLOR;
      } else if (isBpf()) {
        return BPF_LOADER_COLOR;
      } else if (isHb()) {
        return HB_LOADER_COLOR;
      }
    }

    /**
     * Initialize IA insertion and loader render
     * @typedef { function }
     */
    function init() {
      Log.log(`Init --> ${compName}`);

      try {
        if (window.bnpp && typeof window.bnpp._initRequirejs === "function") {
          window.bnpp._initRequirejs.call(window); // rend le chargement de l'IA plus robuste
        }
      } catch (e) {
        Log.error(e);
      }

      const color = setColor();
      element = setElement(color);

      $body.append(element);

      initJsLoader();

      if (
        typeof element !== "undefined" &&
        typeof window?.GlobalSite?.dataIa?.callbackInsertIA === "function"
      ) {
        show();
        startJsLoader();

        window?.GlobalSite?.dataIa
          ?.callbackInsertIA()
          ?.then(() =>
            Promise.all(
              [
                typeof window?.GlobalSite?.dataIa?.iaCustomPromiseBuilder ===
                "function"
                  ? window.GlobalSite?.dataIa?.iaCustomPromiseBuilder?.() // Possibilité d'ajouter un tableau de promesses ex. : Promise.all([promise1, promise2])
                  : WaitAsync.SimpleWaitAsync({
                      predicat() {
                        const _predicat =
                          (window.GlobalSite?.dataIa?.hasOwnProperty(
                            "iaPredicat",
                          ) &&
                            window.GlobalSite?.dataIa?.iaPredicat?.()) ||
                          $(`#${window.GlobalSite.dataIa.iaId}`).children()
                            .length;
                        return _predicat;
                      },
                    }).catch((err) => {
                      Log.error(
                        `${compName} error in Default SimpleWaitAsync -->${err}`,
                      );
                    }),
              ].flat(),
            ),
          )
          .then(() => {
            endJsLoader();
            hide();
          })
          .catch((err) => {
            Log.error(
              `${compName} error in callbackInsertIA -->${JSON.stringify(
                err,
                null,
                2,
              )}`,
            );
            endJsLoader();
            hide();
          });
      }
    }

    function initDelayed() {
      const autoInsertIa = window.GlobalSite?.dataIa?.autoInsertIa;

      if (autoInsertIa === 0) {
        init();
      }
    }

    /**
     * Show loader HTMLElement
     */
    function show() {
      if (typeof element !== "undefined") {
        element.removeClass("hidden");
      }
    }

    /**
     * hide loader HTMLElement
     */
    function hide() {
      if (typeof element !== "undefined") {
        element.addClass("hidden");
      }
    }

    return {
      init,
      initDelayed,
      show,
      hide,
    };
  })();

  $AsyncDomReady().then(function () {
    const autoInsertIa = window.GlobalSite?.dataIa?.autoInsertIa;

    if (autoInsertIa) {
      insertionIA?.init?.();
    }

    $(window)
      .on("ialoader-show", insertionIA.show.bind(insertionIA))
      .on("ialoader-hide", insertionIA.hide.bind(insertionIA));
  });
} catch (e) {}
