import { removeFromLocal, updateLocalStorage } from "./storageTools.mjs";
import { constantes } from "../constantes.mjs";

/**
 * It returns a function that logs a message to the console if the `DEBUG` global variable is set to `true`
 * @param label - The label to be used in the console.log message.
 * @param funcMessageTemplate - This is a function that will be called to log the message.
 * @returns A function that takes two arguments, message and callBack.
 */
export function debugFuncFactory(label, funcMessageTemplate) {
  const isDebug = window.DEBUG;
  const noop = function () {};

  if (!isDebug) {
    return noop;
  }

  if (typeof funcMessageTemplate !== "function") {
    return noop;
  }

  return function (message, callBack) {
    funcMessageTemplate(label, message);

    if (typeof callBack === "function") {
      callBack();
    }
  };
}

debugFuncFactory.help = function () {
  const helpFunc = console.table || console.info;
  helpFunc("exemple usage");
  helpFunc({
    "": 'var debug = GlobalSite.debugFuncFactory("ma fonctionnalité", function(label, message){',
    " ": "   var obj = {};                                                                        ",
    "  ": "                                                                                        ",
    "    ":
      "   obj[label] = message;                                                                ",
    "     ":
      "})                                                                                      ",
  });
};

/**
 * It takes a label and a message as arguments, creates an object with the label as the key and the message as the value,
 * and then logs the object to the console
 * @param label - The label of the message.
 * @param message - The message to be logged.
 */
export function logTemplateMessage(label, message) {
  const obj = {};

  obj[label] = message;
  console.log(obj);
}

/**
 * It takes two arguments, a label and a message, and returns an object with the label as the key and the message as the
 * value
 * @param label - The label of the error message.
 * @param message - The message to be displayed in the console.
 */
export function errorTemplateMessage(label, message) {
  const obj = {};

  obj[label] = message;
  console.error(obj);
}

/**
 * It returns an object with two functions, `log` and `error`, that can be used to log messages to the console
 * @param _label - This is the label that will be used to identify the log.
 * @returns A function that returns an object with two functions, log and error.
 */
export function logFactory(_label) {
  const label = "log:" + _label;
  const _log = debugFuncFactory(label, logTemplateMessage);
  const _error = debugFuncFactory(label, errorTemplateMessage);

  return {
    log(message, callBack) {
      _log(message, callBack);

      return this; // for chaining
    },
    delimiter(character) {
      return this.log((character || "=").repeat(100));
    },
    error(message, callBack) {
      _error(message, callBack);

      return this;
    },
  };
}

export function activeDebug() {
  updateLocalStorage(constantes.LOCAL_KEYS.DEBUG_MODE, true);
}

export function deactiveDebug() {
  removeFromLocal(constantes.LOCAL_KEYS.DEBUG_MODE);
}

const listStubsKeys = [constantes.LOCAL_KEYS.USE_DEBUG_INFOCLIENT_STUB];

export function activeStubs() {
  activeDebug();

  listStubsKeys.forEach(function (item) {
    updateLocalStorage(item, true);
  });
}

export function cleanStubs() {
  listStubsKeys.forEach(function (item) {
    removeFromLocal(item);
  });
}
