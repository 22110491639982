import $ from "jquery";
import { get$body } from "../../coreUtils/jqVariables.mjs";
import { constantes as CST } from "../../constantes.mjs";
import { typeClient } from "../../../bySite/bySiteVars.mjs";

import { once } from "../../coreUtils/once.mjs";
//=============================================
const { CLIENT_TYPES } = CST;
//------------------------- logic -------------------------
/**
 * and([a, b, c, d]) => a && b && c && d
 * @param array
 * @returns {boolean}
 */
export function and(array) {
  return array.reduce(function (acc, cur) {
    return acc && cur;
  }, true);
}

/**
 * or([a, b, c, d]) => a || b || c || d
 * @param array
 * @returns {boolean}
 */
export function or(array) {
  return array.reduce(function (acc, cur) {
    return acc || cur;
  }, false);
}

/**
 *
 * @param val
 * @returns {boolean}
 */
export function not(val) {
  return !val;
}
//------------------------- URI utils ----------------------------------
/**
 *
 * @returns {String}
 */
export const getHostName = once(function () {
  return location.hostname;
});

/**
 *
 * @param hostname
 * @returns {boolean}
 */
export function hostNameIncludesPredicat(hostname) {
  return getHostName().includes(hostname);
}

export const getPathName = once(function () {
  return location.pathname;
});

export function pathIncludesPredicat(partialPath) {
  return getPathName().includes(partialPath);
}

//----------------------- Dom utils -----------------------------------
export const $getBody = get$body;
//----------------------- type clientele utils ------------------------
/**
 *
 * @returns {jQuery|HTMLElement}
 */
export const $getEspaceClientSelector = once(function () {
  const ESPACE_CLIENT_SELECTOR = not(isHb())
    ? [
        ".bottom-header-logoff #logoutCAS",
        "#logoutCas",
        ".bottom-header-logoff a[href*=logoff]:visible",
      ].join(",")
    : [".to-deconnexion", "#logout", ".neo-hb-header__actions .cta--myspace"];

  return $(ESPACE_CLIENT_SELECTOR);
});

/**
 *
 * @returns {boolean}
 */
export const isEspaceClient = once(function () {
  return $getEspaceClientSelector().length >= 1;
});

/**
 *
 * @returns {boolean}
 */
export const isPreview = once(function () {
  return getEnv() === "PREVIEW";
});

/**
 *
 * @returns {boolean}
 */
export const isProd = once(function () {
  return getEnv() === "PROD";
});

/**
 *
 * @returns {boolean}
 */
export const isQualif = once(function () {
  return getEnv() === "QUALIF";
});

/**
 *
 * @returns {boolean}
 */
export const isInte = once(function () {
  return getEnv() === "INTE";
});

/**
 *
 * @returns {boolean}
 */
export const isNoEnv = once(function () {
  return getEnv() === "NO_ENV";
});

/**
 *
 * @returns {*|boolean|boolean}
 */
export const isPart = once(function () {
  return (
    typeClient === CLIENT_TYPES.PART ||
    $getBody().is(".part") ||
    /* bench */ (isNoEnv() && hostNameIncludesPredicat("-part-"))
  );
});

export const isSecure = once(function () {
  return pathIncludesPredicat("/secure/");
});

/**
 *
 * @returns {*|boolean|boolean}
 */
export const isBpf = once(function () {
  return (
    typeClient === CLIENT_TYPES.BPF ||
    $getBody().is(".bpf") ||
    /* bench */ (isNoEnv() && hostNameIncludesPredicat("-bpf-"))
  );
});

/**
 *
 * @returns {*|boolean}
 */
export const isPro = once(function () {
  return (
    typeClient === CLIENT_TYPES.PRO ||
    $getBody().is(".pro") ||
    or(
      ["mabanquepro", "pro.mabanque", "canalnet-pro", "-pro-"].map(
        hostNameIncludesPredicat,
      ),
    )
  );
});

/**
 *
 * @returns {*|boolean}
 */
export const isHb = once(function () {
  return (
    $getBody().is(".hb, .hellobank") || hostNameIncludesPredicat("hellobank")
  );
});

export const isHomePage = once(function () {
  return getPathName() === "/";
});

/**
 *
 * @returns {boolean | *}
 */
export const isConnectionPage = once(function () {
  return (
    getPathName().endsWith("connexion") ||
    getPathName().endsWith("espace-prive") ||
    (isHb() && $("#ident-header").length > 0)
  );
});

/**
 *
 * @returns {any | boolean}
 */
export const isModeWebView = once(function () {
  return (
    (sessionStorage.modeWebview && sessionStorage.modeWebview === "true") ||
    location.search.includes("rc=webview") ||
    location.pathname.includes("webview")
  );
});

export function getEnv() {
  return window?.GlobalSite?.ENVIRONNEMENT || window?.ENVIRONNEMENT;
}

export const getQualifNumber = once(function () {
  let res = 0;
  if (isQualif()) {
    switch (true) {
      case hostNameIncludesPredicat("-we1-ap1"):
        res = 1;
        break;
      case hostNameIncludesPredicat("-we2-ap2"):
        res = 2;
        break;
      case hostNameIncludesPredicat("-we3-ap3"):
        res = 3;
        break;
      case hostNameIncludesPredicat("-we4-ap4"):
        res = 4;
        break;
    }
  }

  return res;
});

/**
 *
 * @returns {boolean}
 */
export const isQualif1 = once(function () {
  return getQualifNumber() === 1;
});

export const isQualif2 = once(function () {
  return getQualifNumber() === 2;
});

export const isQualif3 = once(function () {
  return getQualifNumber() === 3;
});

export const isQualif4 = once(function () {
  return getQualifNumber() === 4;
});

export const isMobile = once(function () {
  return isModeWebView() || window.CANAL === "mobile";
});

export const isDesktop = once(function () {
  return !isModeWebView() || window.CANAL === "desktop";
});

export const isAsyncC2 = once(function isAsyncC2() {
  const res = $.Deferred();

  if (window.ENV === "qualif2") {
    $.ajax({ url: "/content/dam/c2.json" }).always(function (response) {
      res.resolve(response.active === true);
    });
  } else {
    res.resolve(false);
  }

  return res;
});
