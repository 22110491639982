/**
 * "Return a function that can only be called once."
 *
 * The function takes two arguments: a function and a context. The function is called once and the result is stored. The
 * next time the function is called, the stored result is returned
 * @param fn - The function to be executed.
 * @param context - The context in which the function should be called.
 * @returns A function that can only be called once.
 */
export function once(fn, context) {
    let result;

    return function () {
        if (fn) {
            result = fn.apply(context || this, arguments);
            fn = null;
        }

        return result;
    };
}