import { siteSettings } from "./siteSettings.mjs";

/**

 * Retrieves the breakpoints from the site settings.
 *
 * @throws {Error} If siteSettings is missing, scssVars is missing, or breakpoints is missing.
 * @returns {Object} The breakpoints object.
 *
 * @example
 * // Usage example
 * const breakpoints = getBreakPoints();
 * console.log(breakpoints);
 * // Output: { small: '480px', medium: '768px', large: '1024px' }
 */
export function getBreakPoints(
  breakpoints = siteSettings?.scssVars?.["breakpoints"],
) {
  const cloned = structuredClone(breakpoints);
  const normalized = {};

  Object.entries(cloned).forEach(([key, value]) => {
    normalized[key.toLowerCase()] = value;
  });

  return normalized;
}

export const SiteSettings = {
  getBreakPoints,
};
