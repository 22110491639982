import $ from "jquery";
import { buildCanvasLoader } from "../legacyHelpers/Helpers.mjs";
import { trace } from "../../coreUtils/environement.mjs";
import {logFactory} from "../../coreUtils/debug.mjs";

export let jsLoaders;
export let loaderClockCounter = 0;
export let loaderClock;
export let canvasLoaders;

const compName = "jsLoader";
const Log = logFactory(`${compName}.js`);

export function initJsLoader() {
  $("[data-jsloader]").each(function () {
    $(this).on("click", function () {
      const target = $($(this).data("jsloader"));
      if (target.is(":visible")) {
        endJsLoader(target);
      } else {
        startJsLoader(target);
      }
    });
  });

  startJsLoader();
}

export function startJsLoader(loaderEl) {
  jsLoaders = $(".jsloader");

  if (typeof loaderEl == "undefined" && jsLoaders?.length > 0) {
    loaderClockCounter = 0;
    loaderClock = setInterval(function () {
      jsLoaders?.each(function () {
        loaderClockCounter++;
        if (loaderClockCounter >= 29) {
          loaderClockCounter = 0;
        }
        $(this).css({
          "background-position": `-${String(loaderClockCounter * 40)}px 0`,
        });
      });
    }, 1000 / 29);
  } else if (typeof loaderEl != "undefined") {
    loaderClockCounter = 0;
    loaderClock = setInterval(function () {
      loaderClockCounter++;
      if (loaderClockCounter >= 29) {
        loaderClockCounter = 0;
      }
      loaderEl.css({
        "background-position": `-${String(loaderClockCounter * 40)}px 0`,
      });
    }, 1000 / 29);
  }

  //canvas loaders
  canvasLoaders = $(".js-canvas-loader");
  canvasLoaders.each(function () {
    const canvasLoaderParams = {};

    if ($(this).data("js-canvas-loader-fps") != null) {
      canvasLoaderParams.fps = $(this).data("js-canvas-loader-fps");
    }
    if ($(this).data("js-canvas-loader-color") != null) {
      canvasLoaderParams.color = $(this).data("js-canvas-loader-color");
    }
    if ($(this).data("js-canvas-loader-dotnumber") != null) {
      canvasLoaderParams.dotNumber = $(this).data("js-canvas-loader-dotnumber");
    }
    if ($(this).data("js-canvas-loader-dotwidth") != null) {
      canvasLoaderParams.dotWidth = $(this).data("js-canvas-loader-dotwidth");
    }
    if ($(this).data("js-canvas-loader-density") != null) {
      canvasLoaderParams.density = $(this).data("js-canvas-loader-density");
    }
    if ($(this).data("js-canvas-loader-persistence") != null) {
      canvasLoaderParams.persistence = $(this).data(
        "js-canvas-loader-persistence"
      );
    }
    if ($(this).data("js-canvas-loader-speed") != null) {
      canvasLoaderParams.speed = $(this).data("js-canvas-loader-speed");
    }
    if ($(this).data("js-canvas-loader-ease") != null) {
      canvasLoaderParams.ease = $(this).data("js-canvas-loader-ease");
    }
    if ($(this).data("js-canvas-loader-trailmode") != null) {
      canvasLoaderParams.trailMode = $(this).data("js-canvas-loader-trailmode");
    }
    if ($(this).data("js-canvas-loader-taillength") != null) {
      canvasLoaderParams.taillength =
        $(this).data("js-canvas-loader-taillength") > 1
          ? 1
          : $(this).data("js-canvas-loader-taillength");
    }
    if ($(this).data("js-canvas-loader-attenuation") != null) {
      canvasLoaderParams.attenuation =
        $(this).data("js-canvas-loader-attenuation") > 1
          ? 1
          : $(this).data("js-canvas-loader-attenuation");
    }
    if ($(this).data("js-canvas-loader-precision") != null) {
      canvasLoaderParams.precision = $(this).data("js-canvas-loader-precision");
    }
    if ($(this).data("js-canvas-loader-type") != null) {
      canvasLoaderParams.type = $(this).data("js-canvas-loader-type");
    }
    if ($(this).data("js-canvas-loader-linewidth") != null) {
      canvasLoaderParams.linewidth = $(this).data("js-canvas-loader-linewidth");
    }
    if ($(this).data("js-canvas-loader-bgcolor") != null) {
      canvasLoaderParams.bgcolor = $(this).data("js-canvas-loader-bgcolor");
    }
    if ($(this).data("js-canvas-loader-flowerpetalwidth") != null) {
      canvasLoaderParams.flowerPetalWidth = $(this).data(
        "js-canvas-loader-flowerpetalwidth"
      );
    }
    if ($(this).data("js-canvas-loader-flowerborder") != null) {
      canvasLoaderParams.flowerBorder = $(this).data(
        "js-canvas-loader-flowerborder"
      );
    }
    if ($(this).data("js-canvas-loader-floweremptycenter") != null) {
      canvasLoaderParams.flowerEmptyCenter = $(this).data(
        "js-canvas-loader-floweremptycenter"
      );
    }
    if ($(this).data("js-canvas-loader-flowerhighlightcolor") != null) {
      canvasLoaderParams.flowerHighlightColor = $(this).data(
        "js-canvas-loader-flowerhighlightcolor"
      );
    }

    buildCanvasLoader($(this), canvasLoaderParams);
  });
}

export function endJsLoader(loaderEl) {
  if (jsLoaders.length > 0) {
    jsLoaders.hide();
  }
  if (loaderEl != null) {
    loaderEl.hide();
  }
  try {
    clearInterval(loaderClock);
  } catch (e) {
    trace(e);
  }
}
