export function deepFreeze(object) {
  // Récupérer les noms des propriétés de l'objet
  Object.keys(object).forEach((name) => {
    const property = object[name];

    // Si la propriété est un objet et n'est pas gelée, appliquer deepFreeze récursivement
    if (
      typeof property === "object" &&
      property !== null &&
      !Object.isFrozen(property)
    ) {
      deepFreeze(property);
    }
  });

  // Geler l'objet actuel
  return Object.freeze(object);
}
